import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import pick from "lodash/pick";
import sortBy from "lodash/sortBy";
import { CustomValuesEditField } from "PFApp/components/custom_values_edit_field";
import { getCanEditCustomFieldData } from "PFApp/components/custom_values_edit_field/custom_values_edit_field.utils";
import LegacyForm from "PFApp/components/form/legacy_form";
import ProfileEditPanel from "PFApp/profiles/edit/profile_edit_panel";
import { getProfileSectionText } from "PFApp/profiles/edit/profile_edit_sections";
import { AdminInfo } from "PFApp/profiles/edit/sections/admin_info";
import css from "PFApp/profiles/edit/sections/section.less";
import PROFILE_SECTIONS from "PFApp/profiles/profile_sections";
import AutoSelect from "PFComponents/select/autoselect";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { getPermittedTypes, loadForSections } from "PFCore/helpers/custom_type";
import {
  customTypesAccessLevelsAgainstProfile,
  ownProfileCustomTypesAccessLevels
} from "PFCore/helpers/custom_types";
import { getProfileName } from "PFCore/helpers/profile";
import { isProfileFieldPermitted } from "PFCore/helpers/profile_permissions";
import { isFeatureEnabled } from "PFCore/helpers/use_is_feature_enabled";
import { editProfile } from "PFCore/services/admin/profiles/edit_profile";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { AccessLevel, FeatureFlag } from "PFTypes";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

const normalize = (text = "") => text.toLowerCase().replace(/ /g, "");

const findByTermInArray = (term, array) => {
  const termNormalized = normalize(term);

  return array.filter((item) => normalize(item.text).indexOf(termNormalized) !== -1);
};

class DetailsSection extends Component {
  constructor(props) {
    super(props);

    const loadCustomTypes = (section, whiteList) => {
      const ctAccessLevels = props.adminPage
        ? customTypesAccessLevelsAgainstProfile(props.profile, props.currentProfile)
        : ownProfileCustomTypesAccessLevels(props.currentProfile);

      const sectionTypes = loadForSections(section, props.currentProfile.custom_types);
      const types = sortBy(
        getPermittedTypes(sectionTypes, { ctAccessLevels, ro: true, rw: true }),
        "display_as"
      );
      types.forEach(({ name }) => (whiteList[name] = true));

      return types;
    };

    const whiteList = {
      supervisor: true
    };

    this.customTypes = loadCustomTypes(PROFILE_SECTIONS.profile_basic_info, whiteList);
    this.expertiseCTs = loadCustomTypes(PROFILE_SECTIONS.profile_expertise, whiteList);
    this.privateCTs = loadCustomTypes(PROFILE_SECTIONS.profile_private_fields, whiteList);

    // Profile Fields permissions setup
    if (this.isFieldPermitted("first_and_last_name", AccessLevel.ReadOnly)) {
      whiteList["first_name"] = true;
      whiteList["last_name"] = true;
    }
    if (this.isFieldPermitted("summary", AccessLevel.ReadOnly)) {
      whiteList["summary"] = true;
    }

    this.state = {
      errors: {},
      formData: pick(cloneDeep(this.props.profile), ...Object.keys(whiteList)),
      isFormDirty: false
    };
  }

  isFieldPermitted = (field, level = AccessLevel.ReadWrite) => {
    const { profile, permissionsGroup } = this.props;

    return isProfileFieldPermitted(permissionsGroup, profile.permissions_group.id, field, level);
  };

  // Field should be displayed:
  // - if permissions are met (at least read-only permissions are granted for given field)
  // - if editing/displaying own profile and some content is placed inside the field (then even if the field
  //   is `hidden` we show it's content (as read-only then))
  shouldBeDisplayed = (field) => {
    // if we have at least read-only permissions
    if (this.isFieldPermitted(field, AccessLevel.ReadOnly)) {
      return true;
    }

    // if some content is set and editing own profile
    if (this.editingOwnProfile() && !!this.props.profile.summary === true) {
      return true;
    }

    return false;
  };

  editingOwnProfile = () => this.props.currentProfile.id === this.props.profile.id;

  setFormData = (formData) =>
    this.setState((prevState) => ({
      isFormDirty: true,
      formData: {
        ...prevState.formData,
        ...formData
      }
    }));

  handleSuccess = () => {
    this.setState({ errors: {}, isFormDirty: false });
    this.props.handleProfileUpdate();
  };

  handleError = (errors) => {
    this.setState({ errors: errors, isFormDirty: false });
  };

  handleEditProfile = () => editProfile(this.props.profile.id, this.state.formData);

  render() {
    const { currentAccount, adminPage, profile, t, currentProfile } = this.props;
    const isEnabled = isFeatureEnabled(currentAccount);

    const { errors } = this.state;
    const {
      config: {
        profile: { top_skills_limit: coreSkillsLimit, developmental_skills_limit: developmentalSkillsLimit }
      }
    } = currentAccount;

    const { supervisor } = this.props.profile;
    const supervisorValues = supervisor
      ? [
          {
            id: supervisor.id,
            text: supervisor.text || getProfileName(supervisor)
          }
        ]
      : [];

    const customTypeToComponent = (customType, qaIdPrefix) => {
      const { name } = customType;
      const values = this.state.formData[customType.name] || [];
      let topSkillsTag = null;
      let developmentalSkillsTag = null;

      if (name === "skills") {
        const topSkills = values.filter((skill) => skill.top);
        const developmentalSkills = values.filter((skill) => skill.developmental);

        const { locked, lockedTip } = getCanEditCustomFieldData({
          adminPage,
          customType,
          profile: profile,
          position: null,
          currentProfile,
          t
        });

        topSkillsTag = (
          <AutoSelect
            qaId={`${qaIdPrefix}-top-skills`}
            letCreate={false}
            cache={false}
            key={`${topSkills.length}:${values.length}:topSkills`}
            label={t("details.coreSkills")}
            tip={t("details.coreSkillsTip", { count: coreSkillsLimit })}
            locked={locked}
            lockedTip={lockedTip}
            style={{ marginBottom: 24 }}
            values={topSkills}
            multi={customType.kind === "multiple"}
            closeOnChange={customType.kind !== "multiple"}
            maxLength={coreSkillsLimit}
            query={(term) => Promise.resolve(findByTermInArray(term, values))}
            handleChange={(items) => {
              const newCoreIds = items.map((item) => item.id);
              const newValues = values.map((skill) => ({
                ...skill,
                top: newCoreIds.includes(skill.id)
              }));

              this.setFormData({ skills: newValues });
            }}
          />
        );

        developmentalSkillsTag = (
          <AutoSelect
            qaId={`${qaIdPrefix}-developmental-skills`}
            letCreate={false}
            cache={false}
            key={`${developmentalSkills.length}:${values.length}:developmental`}
            label={t("details.developmentalSkills")}
            tip={t("details.developmentalSkillsTip", { count: developmentalSkillsLimit })}
            locked={locked}
            lockedTip={lockedTip}
            style={{ marginBottom: 24 }}
            values={developmentalSkills}
            multi={customType.kind === "multiple"}
            closeOnChange={customType.kind !== "multiple"}
            maxLength={developmentalSkillsLimit}
            query={(term) => Promise.resolve(findByTermInArray(term, values))}
            handleChange={(items) => {
              const newDevelopmentalIds = items.map((item) => item.id);
              const newValues = values.map((skill) => ({
                ...skill,
                developmental: newDevelopmentalIds.includes(skill.id)
              }));

              this.setFormData({ skills: newValues });
            }}
          />
        );
      }

      return (
        <React.Fragment key={name}>
          <CustomValuesEditField
            customType={customType}
            values={values}
            qaIdPrefix={qaIdPrefix}
            errors={errors[name]}
            adminPage={adminPage}
            profile={profile}
            classes={{ root: css.customValueField }}
            letClear
            handleChange={(items) => {
              const newValues = items.map(
                (item) =>
                  find(values, { id: item.id }) ||
                  pick(item, "id", "global_id", "type", "text", "value", "expiry_date")
              );

              this.setFormData({
                [name]: newValues
              });
            }}
          />
          {topSkillsTag}
          {developmentalSkillsTag}
        </React.Fragment>
      );
    };

    return (
      <ProfileEditPanel title={getProfileSectionText("details")}>
        <LegacyForm
          isDirty={this.state.isFormDirty}
          onSuccess={this.handleSuccess}
          onError={this.handleError}
          onSubmit={this.handleEditProfile}
          qaIdPrefix="profile-edit-update"
          growlErrors
          blockNavigationOnChange
        >
          <AdminInfo profile={profile} />

          <div className={css.wrap}>
            <div className={css.nameField}>
              <InputFieldSet
                label={t("details.firstName")}
                qaId="first_name"
                error={errors.first_name}
                locked={!this.isFieldPermitted("first_and_last_name")}
                lockedTip={t("details.noPermsTip")}
                value={this.state.formData.first_name}
                onChange={(first_name) => this.setFormData({ first_name })}
              />
            </div>
            <div className={css.nameField}>
              <InputFieldSet
                label={t("details.lastName")}
                qaId="last_name"
                error={errors.last_name}
                locked={!this.isFieldPermitted("first_and_last_name")}
                lockedTip={t("details.noPermsTip")}
                value={this.state.formData.last_name}
                onChange={(last_name) => this.setFormData({ last_name })}
              />
            </div>
          </div>
          <div className={css.wrap}>
            {this.customTypes.map((item) => customTypeToComponent(item, "profile-basic-info"))}
            {this.shouldBeDisplayed("summary") && (
              <InputFieldSet
                inputType="textarea"
                label={t("details.shortBio")}
                qaId="summary"
                error={errors.summary}
                locked={!this.isFieldPermitted("summary")}
                lockedTip={t("details.noPermsTip")}
                value={this.state.formData.summary}
                onChange={(summary) => this.setFormData({ summary })}
              />
            )}
            {isEnabled(FeatureFlag.ReportsTo) && (
              <div data-qa-id="profile-edit-current-position-reports-to" style={{ marginBottom: 24 }}>
                <AutoSelect
                  letClear={true}
                  multi={false}
                  query={(term) => fetchProfilesOptions({ term })}
                  filterOptions={(response) => response.filter((item) => currentProfile.id !== item.id)}
                  values={supervisorValues}
                  label={t("details.reportsTo")}
                  handleChange={(value) => {
                    // setting null means no supervisor; wile undefined (or rather no key at all)
                    // is to have no value i.e. to not post any value at all (no change on api side)
                    this.setFormData({ supervisor: value[0] || null });
                  }}
                  closeOnChange={true}
                />
              </div>
            )}
            {!!this.expertiseCTs.length && <h3 className={css.header}>{t("details.expertise")}</h3>}
            <div>
              {!!this.expertiseCTs.length &&
                this.expertiseCTs.map((item) => customTypeToComponent(item, "profile-expertise"))}
            </div>

            {!!this.privateCTs.length && <h3 className={css.header}>{t("details.fieldsOnlyYouCanSee")}</h3>}
            <div>
              {!!this.privateCTs.length &&
                this.privateCTs.map((item) => customTypeToComponent(item, "profile-expertise"))}
            </div>
          </div>
        </LegacyForm>
      </ProfileEditPanel>
    );
  }
}

export default withTranslation("profiles", { keyPrefix: "edit.sections" })(DetailsSection);

DetailsSection.propTypes = {
  profile: PropTypes.any.isRequired,
  currentProfile: PropTypes.any.isRequired,
  currentAccount: PropTypes.any.isRequired,
  adminPage: PropTypes.bool.isRequired,
  handleProfileUpdate: PropTypes.func,
  permissionsGroup: PropTypes.any,
  t: PropTypes.func
};
