import { useQueryClient } from "@tanstack/react-query";

import { proficiencyLevelsKeys } from "./query_keys";

export const useProficiencyLevelsInvalidate = () => {
  const cache = useQueryClient();

  return {
    invalidateProficiencyLevels: () => {
      cache.invalidateQueries(proficiencyLevelsKeys.all());
    }
  };
};
