import map from "lodash/map";
import { useProficiencyLevels } from "PFCore/hooks/queries/proficiency_levels/use_proficiency_levels";
import { Experience } from "PFTypes";

type UseExperienceConfigReturn = {
  experienceLevels: Experience[];
};

export const useExperienceConfig = (): UseExperienceConfigReturn => {
  const { data } = useProficiencyLevels<Experience[]>({
    select: (proficiencyLevels) => map(proficiencyLevels, "numericalValue").sort()
  });

  return {
    experienceLevels: data || []
  };
};
