import { Booking } from "PFTypes";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { BookingFormValues } from "../booking_form_provider";
import { BookingFormData, BookingFormDataCreateMode, BookingFormMode } from "../use_booking_form";
import { useInitialBookingsForCreate } from "./use_initial_bookings_for_create";
import { useInitialBookingsForEditOrClone } from "./use_initial_bookings_for_edit_or_clone";

type UseBookingInitialData = {
  initialData: BookingFormData;
  mode: BookingFormMode;
  booking?: Booking;
};
type UseBookingInitialDataReturn = {
  loading: boolean;
};

export const useInitialBookings = ({
  initialData = {},
  mode,
  booking
}: UseBookingInitialData): UseBookingInitialDataReturn => {
  const { setValue } = useFormContext<BookingFormValues>();

  const isCreateMode = mode === BookingFormMode.Create;
  const isCloneMode = [BookingFormMode.CloneSingle, BookingFormMode.CloneAll].includes(mode);

  const {
    bookings: initialBookingsForCreate,
    globalTitle,
    globalDescription: globalDescriptionForCreate,
    loading: initialBookingsForCreateLoading
  } = useInitialBookingsForCreate({
    initialData: initialData as BookingFormDataCreateMode,
    enabled: isCreateMode
  });

  const { bookings: initialBookingsForEditOrClone, loading: initialBookingsForEditOrCloneLoading } =
    useInitialBookingsForEditOrClone({
      initialBooking: booking,
      enabled: !isCreateMode,
      mode
    });

  const bookings = isCreateMode ? initialBookingsForCreate : initialBookingsForEditOrClone;
  const loading = initialBookingsForCreateLoading || initialBookingsForEditOrCloneLoading;

  const globalDescriptionForClone = isCloneMode ? booking?.description : undefined;

  const globalDescription = globalDescriptionForClone ?? globalDescriptionForCreate;

  useEffect(() => {
    setValue("bookings", bookings);
    if (isCreateMode || isCloneMode) {
      setValue("globalTitle", globalTitle);
      setValue("globalDescription", globalDescription);
    }
  }, [bookings, globalTitle, globalDescription]);

  return {
    loading
  };
};
