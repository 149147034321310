import { VariantBody, VariantLabel } from "../typography/typography.types";
import { MultiToggleProps } from "./multi_toggle";

export const getTextVariant = (
  isSelected: boolean,
  textVariant: MultiToggleProps<any>["textVariant"]
): VariantBody | VariantLabel => {
  if (textVariant === "label") {
    return isSelected ? "labelBold" : "labelRegular";
  }

  if (textVariant === "labelSmall") {
    return isSelected ? "labelSmallBold" : "labelSmallRegular";
  }

  return isSelected ? "bodyBold" : "bodyRegular";
};
