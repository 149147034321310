import { Button } from "PFComponents/button/button";
import { LoadingDots } from "PFComponents/loading_dots/loading_dots";
import { Typography } from "PFComponents/typography";
import isIOS from "PFCore/helpers/ios";
import isSafari from "PFCore/helpers/is_safari";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import DownloadIcon from "PFIcons/download.svg";
import { PermissionRule } from "PFTypes";
import { DocFormat } from "PFTypes/doc_format";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import css from "./document_buttons.module.scss";
import { UseDownloadDocument, useDownloadDocument } from "./use_download_document";

export type DocumentButtonComponentProps = {
  onClick: VoidFunction;
  title: string;
};

type DocumentButtonsProps = UseDownloadDocument & {
  exportOptions?: DocFormat[];
  hideLabel?: boolean;
  buttonComponent?: React.ComponentType<DocumentButtonComponentProps>;
};

export const DocumentButtons = memo(
  ({
    generateDocument,
    exportOptions = [DocFormat.Doc, DocFormat.Pdf, DocFormat.Csv],
    hideLabel,
    buttonComponent: ButtonComponent
  }: DocumentButtonsProps) => {
    const { t } = useTranslation(["core", "translation"]);
    const { handleDownloadClick, fileUrl, isLoading } = useDownloadDocument({ generateDocument });

    const useAnchorTag = isIOS() || isSafari();

    const renderDownloadButtons = () => {
      const doc = exportOptions.indexOf(DocFormat.Doc) >= 0;
      const pdf = exportOptions.indexOf(DocFormat.Pdf) >= 0;
      const csv = exportOptions.indexOf(DocFormat.Csv) >= 0;

      const label = isIOS() ? t("core:documentButtons.iosPhrase") : t("core:documentButtons.phrase");

      const ButtonTag = ButtonComponent || Button;
      return (
        <>
          {!hideLabel && <Typography variant="bodyRegular">{label}</Typography>}
          {doc && (
            <ButtonTag
              kind="text"
              onClick={() => handleDownloadClick(DocFormat.Doc)}
              title={t("core:documentButtons.exportToDoc")}
            >
              .doc
            </ButtonTag>
          )}
          {pdf && (
            <ButtonTag
              kind="text"
              onClick={() => handleDownloadClick(DocFormat.Pdf)}
              title={t("core:documentButtons.exportToPdf")}
            >
              .pdf
            </ButtonTag>
          )}
          {csv && (
            <ButtonTag
              kind="text"
              onClick={() => handleDownloadClick(DocFormat.Csv)}
              title={t("core:documentButtons.exportToCsv")}
            >
              .csv
            </ButtonTag>
          )}
        </>
      );
    };

    const renderDownloadIframe = () => {
      const style = {
        display: "inline-block",
        fill: "currentColor",
        verticalAlign: "middle",
        lineHeight: "15px"
      };

      return useAnchorTag ? (
        <span>
          <a target="_blank" href={fileUrl} rel="noopener noreferrer">
            <span style={style}>
              <DownloadIcon width={16} height={16} />
            </span>
            {t("translation:download")}
          </a>
        </span>
      ) : (
        <iframe src={fileUrl} style={{ display: "none" }} title={t("translation:download")} />
      );
    };

    const canExportDocs = useIsCurrentUserPermittedTo(PermissionRule.ExportDocuments);

    if (!canExportDocs) {
      return null;
    }

    return (
      <div className={css.documentButtons} data-qa-id="export-doc-trigger">
        {isLoading && <LoadingDots />}
        {!isLoading && ((useAnchorTag && !fileUrl) || !useAnchorTag) && renderDownloadButtons()}
        {!isLoading && fileUrl && renderDownloadIframe()}
      </div>
    );
  }
);

DocumentButtons.displayName = "DocumentButtons";
