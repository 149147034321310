import { useExperienceConfig } from "PFApp/hooks/use_experience_config";
import { Experience } from "PFTypes";

type UseFontExperienceIconsReturn = {
  experienceToFontIconMap: Partial<Record<Experience, string>>;
  experienceFontName: string;
};

// Icons from PF-experience fonts matching experience level
const PF_EXPERIENCE_3_ICONS_MAP = {
  [Experience.Basic]: "\ue805",
  [Experience.Intermediate]: "\ue809",
  [Experience.Advanced]: "\ue807"
};

const PF_EXPERIENCE_4_DEVELOPING_ICONS_MAP = {
  [Experience.Basic]: "\ue804",
  [Experience.Developing]: "\ue805",
  [Experience.Intermediate]: "\ue806",
  [Experience.Advanced]: "\ue807"
};

const PF_EXPERIENCE_4_PROFICIENT_ICONS_MAP = {
  [Experience.Basic]: "\ue804",
  [Experience.Intermediate]: "\ue805",
  [Experience.Proficient]: "\ue806",
  [Experience.Advanced]: "\ue807"
};

const PF_EXPERIENCE_5_ICONS_MAP = {
  [Experience.Basic]: "\ue80B",
  [Experience.Developing]: "\ue805",
  [Experience.Intermediate]: "\ue806",
  [Experience.Proficient]: "\ue809",
  [Experience.Advanced]: "\ue807"
};

export const useFontExperienceIcons = (): UseFontExperienceIconsReturn => {
  const { experienceLevels } = useExperienceConfig();

  const isDevelopingEnabled = experienceLevels.includes(Experience.Developing);

  if (experienceLevels.length === 4) {
    const experienceToFontIconMap = isDevelopingEnabled
      ? PF_EXPERIENCE_4_DEVELOPING_ICONS_MAP
      : PF_EXPERIENCE_4_PROFICIENT_ICONS_MAP;

    return {
      experienceToFontIconMap,
      experienceFontName: "PF-experience-4"
    };
  }

  if (experienceLevels.length === 5) {
    return {
      experienceToFontIconMap: PF_EXPERIENCE_5_ICONS_MAP,
      experienceFontName: "PF-experience-5"
    };
  }

  return {
    experienceToFontIconMap: PF_EXPERIENCE_3_ICONS_MAP,
    experienceFontName: "PF-experience-3"
  };
};
