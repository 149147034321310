import classNames from "classnames";
import css from "PFApp/booking/parts/overview/detail_view/detail_view.module.scss";
import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { useAvailabilityFormatter } from "PFCore/helpers/availability";
import { useReadableAvailability } from "PFCore/helpers/use_readable_availability";
import { AvailabilityRule } from "PFTypes";
import { useTranslation } from "react-i18next";

import { Label } from "../parts/label";

type DurationProps = { availability: AvailabilityRule };

export const Duration = ({ availability }: DurationProps) => {
  const { t } = useTranslation();

  const { readableAvailability } = useReadableAvailability({
    availability: availability as AvailabilityRule
  });
  const { availabilityRangesFormat, availabilityIntervalFormat } = useAvailabilityFormatter();
  const content =
    availability.mode === "time_rule"
      ? availabilityRangesFormat(availability, true, css.tooltipContent)
      : availabilityIntervalFormat(availability);

  return (
    <span className={classNames(css.item, css.duration)}>
      <span>
        <div className={css.alignCenter}>
          <Label icon="calendar" text={t("duration")} />
          {availability?.ranges && (
            <Tooltip
              content={
                <Typography variant="bodyRegular" noMargin className={css.durationTooltip}>
                  {content}
                </Typography>
              }
              appendTo={"parent"}
            >
              <span className={css.alignCenter}>
                <Icon name="info" size="sm" />
              </span>
            </Tooltip>
          )}
        </div>
        <Typography variant="bodyBold" noMargin>
          {readableAvailability}
        </Typography>
      </span>
    </span>
  );
};
