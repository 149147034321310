import moment from "moment";
import { InlineRangeCalendar } from "PFComponents/calendar/inline_calendar";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import useFiltersRange from "PFCore/hooks/use_filters_range";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

const ReportingRangeItem = ({ filter, onChange, error, portalRef, disabled }) => {
  const { formatISODate } = useDateFormatter();

  const { pastOptions, futureOptions } = useFiltersRange();
  const [bufferedValue, setBufferedValue] = useState(filter.value);

  useEffect(() => {
    setBufferedValue(filter.value);
  }, [filter.value]);

  const { start, end } = bufferedValue || {};

  const options = useMemo(() => (filter.allowFuture ? futureOptions : pastOptions), []);

  const handleChange = (start, end) => {
    const formattedValue = {
      start: formatISODate(start),
      end: formatISODate(end)
    };
    setBufferedValue(formattedValue);
    onChange(formattedValue);
  };

  return (
    <div style={{ padding: "0px 10px", margin: "0px 10px", position: "relative" }}>
      <InlineRangeCalendar
        startDate={start ? moment(start) : null}
        minDate={formatISODate(moment().subtract(2, "years"))}
        maxDate={filter?.allowFuture ? formatISODate(moment().add(3, "years")) : formatISODate()}
        endDate={end ? formatISODate(end) : null}
        label={filter.title}
        style={{ marginLeft: -10 }}
        handleChange={handleChange}
        options={options}
        error={error}
        portalRef={portalRef}
        disabled={disabled}
      />
    </div>
  );
};

ReportingRangeItem.propTypes = {
  onChange: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.oneOf(["reporting_range"]),
    allowFuture: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.oneOf([""]), // empty string is valid and consider null
      PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string
      })
    ])
  }).isRequired,
  error: PropTypes.string,
  portalRef: PropTypes.object,
  disabled: PropTypes.bool
};

export default ReportingRangeItem;
