import { find } from "lodash";
import ProfileEditNav from "PFApp/profiles/edit/nav/profile_edit_nav";
import css from "PFApp/profiles/edit/profile_edit_page.less";
import ProfileEditTip from "PFApp/profiles/edit/profile_edit_tip";
import NotFoundComponent from "PFComponents/not_found/not_found";
import { getProfileName } from "PFCore/helpers/profile";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ProfileEditPage = ({
  profile,
  adminPage,
  permissionsGroup,
  section,
  sections,
  tip,
  tipName,
  handleProfileUpdate
}) => {
  const { t } = useTranslation("profiles");
  const isEnabled = useIsFeatureEnabled();
  const { data: currentProfile } = useCurrentProfile();
  const { data: currentAccount } = useCurrentAccount();

  const getSectionComponent = () => {
    const sectionContent = find(sections, { section: section });
    if (
      !sectionContent ||
      (sectionContent.requireFeature && !isEnabled(sectionContent.requireFeature)) ||
      (sectionContent.if && !sectionContent.if())
    ) {
      return null;
    }
    return sectionContent ? sectionContent.clazz : null;
  };

  const Section = getSectionComponent();

  const headerTitle = adminPage
    ? t("edit.updateProfileName", { profileName: getProfileName(profile) })
    : t("edit.updateYourProfile");

  return (
    <div className={css.root}>
      <h1 className={css.header}>
        <span>{headerTitle}</span>
      </h1>
      <div className={css.nav}>
        <ProfileEditNav
          currentSection={section}
          sections={sections}
          adminPage={adminPage}
          // to be migrated to use profile (The one on the store, not the model)
          profile={profile}
        />
      </div>
      <div className={css.section}>
        <ProfileEditTip tip={tip} tipName={tipName} />
        {Section && (
          <Section
            profile={profile}
            currentProfile={currentProfile}
            currentAccount={currentAccount}
            adminPage={adminPage}
            permissionsGroup={permissionsGroup}
            handleProfileUpdate={handleProfileUpdate}
          />
        )}
        {!Section && <NotFoundComponent message={t("edit.contentCouldntLoad")} />}
      </div>
    </div>
  );
};

ProfileEditPage.propTypes = {
  profile: PropTypes.any.isRequired,
  section: PropTypes.string.isRequired,
  sections: PropTypes.array,
  adminPage: PropTypes.bool,
  tip: PropTypes.string,
  tipName: PropTypes.string,
  permissionsGroup: PropTypes.any.isRequired,
  handleProfileUpdate: PropTypes.func
};

ProfileEditPage.defaultProps = {
  sections: [],
  adminPage: false,
  tip: null,
  tipName: null
};

export default ProfileEditPage;
