import classNames from "classnames";
import { useExperienceConfig } from "PFApp/hooks/use_experience_config";
import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import { Stack } from "PFComponents/containers";
import { ExperienceLevel } from "PFComponents/experience_level";
import { Experience } from "PFTypes";

import css from "./experience_multi_toggle.module.scss";

type ExperienceOptionProps = {
  experience: Experience;
  selected: boolean;
  isRequirement?: boolean;
};

export const ExperienceOption = ({ isRequirement, experience, selected }: ExperienceOptionProps) => {
  const experienceLabelMap = useExperienceLabelMap();
  const { experienceLevels } = useExperienceConfig();

  return (
    <Stack className={classNames(css.fullWidth, css.experienceOption)} center>
      <ExperienceLevel
        isRequirement={isRequirement}
        {...{ [isRequirement ? "requiredExperience" : "experience"]: experience }}
        theme={selected ? "dark" : "light"}
        experienceLevels={experienceLevels}
        showTooltip={false}
      />
      <span className={classNames(css.fullWidth, css.clipOverflow)} title={experienceLabelMap[experience]}>
        {experienceLabelMap[experience]}
      </span>
    </Stack>
  );
};
