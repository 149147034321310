import { CalendarRange } from "PFCore/types/calendar_range";
import { Filters, Value } from "PFTypes";

export const workforceMetricsKeys = {
  all: () => ["workforceMetrics"],
  single: (calendarRange: CalendarRange, filters: Filters<Value> = {}) => [
    ...workforceMetricsKeys.all(),
    JSON.stringify(calendarRange),
    JSON.stringify(filters)
  ]
};
