import { includes } from "lodash";
import { canAddMatchableTypeValue } from "PFCore/helpers/activities";
import { activityCustomTypesAccessLevels } from "PFCore/helpers/custom_types";
import {
  AccessLevel,
  ActivityPureCustomValue,
  CurrentProfile,
  CustomType,
  ParsedSchemaProperty,
  Template
} from "PFTypes";

export const canEditAttribute = (currentProfile: CurrentProfile, customType: CustomType) => {
  const accessLevels = activityCustomTypesAccessLevels(currentProfile);

  return accessLevels[customType.id] === AccessLevel.ReadWrite;
};

export const canAddNewAttribute = (
  keywords: ActivityPureCustomValue[],
  kind: ParsedSchemaProperty["kind"],
  matchableTypes: ParsedSchemaProperty[],
  matchableType: ParsedSchemaProperty
) => {
  const isMatchablePermittedToAddNewAttribute = canAddMatchableTypeValue(matchableType, matchableTypes);

  const isMultiple = kind === "multiple";

  return isMatchablePermittedToAddNewAttribute && (isMultiple || !keywords.length);
};

export const canRemoveAttribute = (
  keywords: ActivityPureCustomValue[],
  matchableType: ParsedSchemaProperty,
  template: Template
) => {
  const isRequired = includes(template.schema.required, matchableType.name);

  return isRequired ? keywords?.length > 1 : true;
};
