import { AuditPlannerExceptionKey, AuditPlannerExceptionsDictionary } from "PFTypes";

export const getExceptionDetails = (exception: AuditPlannerExceptionsDictionary | undefined) => {
  const { availability, compliance } = exception || {};

  return {
    availability,
    compliance,
    isMultiple: !!(availability && compliance),
    keys: Object.keys(exception || {}) as AuditPlannerExceptionKey[]
  };
};
