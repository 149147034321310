import { groupBy, mapValues } from "lodash";
import { Experience, ProfileCustomValue } from "PFTypes";

// TODO: [ENG-2876] Simplify helpers, create Node type with d3-hierarchy, keep data consistent
type Node = {
  children?: Node[];
  name?: string;
  value?: number;
  count?: number;
  data?: Node | ProfileCustomValue;
};

export const getChildIndex = (children: Node[], name: string): number =>
  children.findIndex((child) => child.name === name);

// Add a named category/skill to the child of an entry, adding it new if it doesn't exist currently,
// or just appending it and incrementing the value of the entry
export const addEntryToChildren = (children: Node[], value: string): number => {
  let index = getChildIndex(children, value);

  if (index < 0) {
    children.push({ children: [], name: value });
    index = children.length - 1;
  }
  children[index].count = children[index].children?.length || 1; // TODO: [ENG-2876] Is this line needed? I don't see "count" usages

  return index;
};

// Get skill entry matching given skill name
export const getSkillEntry = (
  skillName: string,
  profileSkills: ProfileCustomValue[]
): Partial<ProfileCustomValue> & { name: string } => {
  const skillEntry = profileSkills.find(({ value }) => value === skillName);

  return { name: skillName, ...(skillEntry || {}) };
};

// Quick tree traversal for flattening out some children of a tree
const getChildren = (node: Node | ProfileCustomValue) => {
  if ((node as ProfileCustomValue).id) {
    return node;
  } else if ((node as Node).children) {
    return (node as Node).children?.map((children) => getChildren(children));
  }

  return [];
};

// For getting an array of all the skills in a tree as a flat array
export const getAllSkillsFromTree = (skillTree: Node | ProfileCustomValue): ProfileCustomValue[] => {
  // Just for ease of use, make everything an array
  if ((skillTree as Node).children) {
    return [skillTree].reduce((skills, tree) => [...skills, ...getChildren(tree)].flat(), []);
  }

  return [skillTree as ProfileCustomValue];
};

type GroupedSkills = Partial<Record<Experience, ProfileCustomValue[]>>;

export const groupSkillsByExperience = (skills: ProfileCustomValue[]): GroupedSkills =>
  groupBy(skills, ({ experience }) => experience || Experience.Intermediate);

type SkillsCounters = Record<Experience, number>;

export const getProficiencyIcon = (
  nodeData: Node,
  experienceToFontIconMap: Partial<Record<Experience, string>>
): string => {
  const skills = nodeData.data ? getAllSkillsFromTree(nodeData.data) : [];

  const experienceCounters = mapValues(groupSkillsByExperience(skills), "length") as SkillsCounters;

  const highestExperience = Object.keys(experienceCounters).reduce(
    (previousExperience, currentExperience) =>
      experienceCounters[previousExperience] > experienceCounters[currentExperience]
        ? previousExperience
        : currentExperience,
    Experience.None
  );

  return experienceToFontIconMap[highestExperience];
};

// ECharts does not allow you to selectively display various layers in a treemap. To get around this, we
// just remove all the data which isn't part of the current layer by removing its children key
export const removeChildrenFromLayer = (layer: Node[]): Node[] =>
  layer.map(({ name, value, ...data }) => ({
    name,
    value,
    data
  }));

export const getDataFromPath = (data: Node[], path: number[]): Node[] => {
  if (path.length === 0) {
    return data;
  }

  if (!data[path[0]]) {
    return [];
  }

  let toReturn = { ...data[path[0]] };

  path.shift(); // Remove the first part of the path, clearer than { [0]: first, ...rest }
  path.forEach((index) => {
    toReturn = toReturn.children?.[index] || {};
  });

  return toReturn.children || [];
};

export const ratio = (value: number, minValue: number, maxValue: number): number =>
  (value - minValue) / (maxValue - minValue || 1);
