import { isEmpty } from "lodash";
import { LoadingDots } from "PFComponents/loading_dots";
import { useProfilesBookings, useProfilesWarnings } from "PFCore/hooks/queries/profile";
import { Profile } from "PFTypes";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DetailsPanelData } from "../../../details_panel_context/details_panel_context";
import { WarningsDetail } from "../../warnings_detail/warnings_detail";
import { WarningsFilter } from "../../warnings_detail/warnings_filter";
import { useOverbookingRanges } from "../use_overbooking_ranges";
import { OverbookingsList } from "./overbookings_list";
import { useOverbookingsFilter } from "./use_overbookings_filter";

type OverbookingsDetailProps = {
  profile: Profile | undefined;
  isProfileLoading: boolean;
  data: DetailsPanelData;
  onDeleted?: (entity: any) => Promise<void>;
  onEdited?: () => void;
  goToDate?: (date: Date) => void;
};

export const OverbookingsDetail = ({
  profile,
  isProfileLoading,
  data,
  onDeleted,
  onEdited,
  goToDate
}: OverbookingsDetailProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "details.overbookings" });

  const { overbookings: initialOverbookings, calendarRange, potentialBookings } = data.overbookingsDetails!; // overbookings side panel needs to have overbookingsDetails provided

  const {
    currentInitialOverbookings,
    isFilterEnabled,
    isSingleOption,
    options,
    selectedOption,
    setSelectedOption
  } = useOverbookingsFilter({
    initialOverbookings,
    overbookingsDetails: data.overbookingsDetails
  });

  const areInitialOverbookings = !isEmpty(currentInitialOverbookings);

  const { warningsByProfile, isLoading: isWarningsLoading } = useProfilesWarnings({
    profiles: profile ? [{ id: profile.id }] : [],
    calendarRange,
    enabled: !!profile && !areInitialOverbookings
  });
  const rowKey = `profile_${data.id}`;
  const { bookingsByRowKey, isFetching: isLoadingBookings } = useProfilesBookings({
    profiles: profile ? [{ id: profile?.id, rowKey }] : [],
    dateRange: calendarRange,
    enabled: !!profile
  });
  const bookings = [...(bookingsByRowKey[rowKey] ?? []), ...(potentialBookings ?? [])];

  const overbookings = useMemo(() => {
    if (areInitialOverbookings) {
      return currentInitialOverbookings ?? [];
    }
    return profile && !isWarningsLoading ? warningsByProfile[profile.id].overbookings : [];
  }, [areInitialOverbookings, profile, isWarningsLoading, warningsByProfile, currentInitialOverbookings]);

  const { rangesWithBookings } = useOverbookingRanges({
    overbookings,
    bookings
  });

  const isLoading =
    !profile || isProfileLoading || isLoadingBookings || (!areInitialOverbookings && isWarningsLoading);

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <WarningsDetail
      customHeader={
        isFilterEnabled && (
          <WarningsFilter
            isSingleOption={isSingleOption}
            label={t("bookingsFrom")}
            singleOptionValue={data.overbookingsDetails?.filterConfig?.filteredActivity.name}
            options={options}
            selectedOption={selectedOption}
            onChangeSelectedOption={setSelectedOption}
          />
        )
      }
      summary={t("ranges", { count: rangesWithBookings.length })}
    >
      {(selectedOrder) => (
        <OverbookingsList
          rangesWithBookings={rangesWithBookings}
          profile={profile}
          goToDate={goToDate}
          onDeleted={onDeleted}
          onEdited={onEdited}
          selectedSort={selectedOrder}
        />
      )}
    </WarningsDetail>
  );
};
