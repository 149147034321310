import classNames from "classnames";
import { useExperienceConfig } from "PFApp/hooks/use_experience_config";
import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import PinIcon from "PFIcons/pin_alt.svg";
import { Experience } from "PFTypes";
import PropTypes from "prop-types";

import { Typography } from "../typography";
import css from "./ranking_indicator.module.scss";

const experienceToBareTextMap = {
  [Experience.Basic]: "basic",
  [Experience.Developing]: "developing",
  [Experience.Intermediate]: "intermediate",
  [Experience.Proficient]: "proficient",
  [Experience.Advanced]: "advanced"
};

const RankingIndicator = ({ values, myRanking }) => {
  const { experienceLevels } = useExperienceConfig();
  const experienceLabelMap = useExperienceLabelMap();

  const tierWidthPercentage = 100 / experienceLevels.length;
  const myRankingIndex = experienceLevels.findIndex((experience) => experience === myRanking);
  const myRankingPostion = `${myRankingIndex * tierWidthPercentage + tierWidthPercentage / 2}%`;

  return (
    <div className={css.wrapper}>
      <div className={css.rankingBar}>
        {myRanking ? (
          <div className={css.rankingIndicator} style={{ left: myRankingPostion }}>
            <PinIcon height={32} width={32} />
          </div>
        ) : null}
        {experienceLevels.map((experience) => {
          const bareExperienceText = experienceToBareTextMap[experience];

          return (
            <Typography key={experience} variant="bodyBold" className={classNames(css.tier)} noMargin>
              {values?.[bareExperienceText] || 0}
            </Typography>
          );
        })}
      </div>
      <div className={css.legend}>
        {experienceLevels.map((experience) => (
          <Typography
            key={experience}
            variant="labelBold"
            className={classNames(css.label, {
              [css.disabled]: values?.[experienceToBareTextMap[experience]] === 0
            })}
            title={experienceLabelMap[experience]}
            clipOverflow
          >
            {experienceLabelMap[experience]}
          </Typography>
        ))}
      </div>
    </div>
  );
};

RankingIndicator.propTypes = {
  values: PropTypes.shape({
    basic: PropTypes.number,
    developing: PropTypes.number,
    intermediate: PropTypes.number,
    proficient: PropTypes.number,
    advanced: PropTypes.number
  }),
  myRanking: PropTypes.number
};

export default RankingIndicator;
