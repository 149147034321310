import orderBy from "lodash/orderBy";
import { useExperienceConfig } from "PFApp/hooks/use_experience_config";
import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import { ECOption } from "PFCore/helpers/echarts_wrapper";
import { EconomicsScenarioDetails, Experience } from "PFTypes";
import { useTranslation } from "react-i18next";

export const useSkillsChartModal = (skillsRadarData: EconomicsScenarioDetails["skillsRadarData"]) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.charts.skills" });
  const { experienceLevels } = useExperienceConfig();
  const experienceLabelMap = useExperienceLabelMap();

  const getExperienceChartValue = (experience: Experience | null) => {
    if (!experience) {
      return 0;
    }

    return experienceLevels.indexOf(experience) + 1;
  };

  const sortedSkills = orderBy(skillsRadarData, "skill.value", "desc");
  const skillsNames = sortedSkills.map(({ skill }) => skill.value);
  const requiredExperienceValues = sortedSkills.map(({ requiredExperience }) => ({
    value: getExperienceChartValue(requiredExperience)
  }));
  const possessedExperienceValues = sortedSkills.map(({ possessedExperience }) => ({
    value: getExperienceChartValue(possessedExperience)
  }));

  const series = [
    { name: "required", data: requiredExperienceValues },
    { name: "filled", data: possessedExperienceValues }
  ];

  const formatExperienceLabel = (value: number) => {
    if (!value) {
      return t("no");
    }

    const experience = experienceLevels[value - 1];

    return experienceLabelMap[experience];
  };

  const customOptions: ECOption = {
    xAxis: {
      type: "value",
      axisLabel: {
        formatter: formatExperienceLabel
      },
      splitLine: {
        lineStyle: {
          type: "dashed"
        }
      },
      max: experienceLevels.length,
      interval: 1
    }
  };

  return {
    skillsNames,
    series,
    customOptions
  };
};
