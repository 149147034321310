import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import { Select } from "PFComponents/select/select";
import { isSaasAdmin } from "PFCore/helpers/profile";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useProficiencyLevelsInvalidate } from "PFCore/hooks/queries/proficiency_levels/use_proficiency_levels_invalidate";
import { useCurrentProfile, useCurrentProfileUpdate } from "PFCore/hooks/queries/profile";
import { useMemo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ProfileEditPanel from "../../profile_edit_panel";
import { getProfileSectionText } from "../../profile_edit_sections";
import css from "./laguage_settings.module.scss";

type LanguageForm = {
  locale: string | undefined;
};

export const LanguageSettingsSection = (): JSX.Element => {
  const { t: profilesT } = useTranslation("profiles", { keyPrefix: "edit.sections" });
  const { t } = useTranslation(["locales", "translation"]);

  const {
    data: {
      customization: { allowed_locales: allowedLocales, locale: defaultAccountLocale }
    }
  } = useCurrentAccount();
  const { invalidateProficiencyLevels } = useProficiencyLevelsInvalidate();

  const { data: currentProfile } = useCurrentProfile();

  const { locale } = currentProfile;

  const growl = useGrowl();
  const saasAdmin = isSaasAdmin(currentProfile);

  const { handleSubmit, control } = useForm<LanguageForm>({
    defaultValues: {
      locale
    }
  });

  const { mutate: updateProfile, isLoading: isUpdateProfileLoading } = useCurrentProfileUpdate({
    onSuccess: () => {
      invalidateProficiencyLevels();
      growl({
        message: profilesT("settingsUpdatedMessage")
      });
    }
  });

  const handleSave: SubmitHandler<LanguageForm> = (formData) => updateProfile(formData);

  const localeOptions = useMemo(
    () =>
      (allowedLocales ?? []).map((locale) => ({
        id: locale,
        displayElement:
          locale === defaultAccountLocale
            ? profilesT("languageSettings.defaultLocale", {
                locale: t(locale as unknown as TemplateStringsArray)
              })
            : t(locale as unknown as TemplateStringsArray),
        item: locale,
        disabled: saasAdmin && locale !== "en"
      })),
    [allowedLocales, t, profilesT]
  );

  return (
    <ProfileEditPanel title={getProfileSectionText("language_settings")}>
      <div className={css.root}>
        <form className={css.form}>
          <Controller
            control={control}
            name={"locale"}
            render={({ field: { onChange, value } }) => (
              <Select
                label={profilesT("languageSettings.language")}
                tip={profilesT("languageSettings.languageHint")}
                style={{ width: "100%" }}
                value={localeOptions.find(({ id }) => id === value)?.displayElement}
                options={localeOptions}
                onChange={onChange}
                selectedIndex={localeOptions.findIndex(({ id }) => id === value)}
                controlledValue
                readOnly
              />
            )}
          />
        </form>

        <Button
          text={t("translation:update")}
          className={css.button}
          kind="primary"
          disabled={isUpdateProfileLoading}
          onClick={handleSubmit(handleSave)}
        />
      </div>
    </ProfileEditPanel>
  );
};
