import SkillsExplorer from "PFApp/profiles/show/parts/charts/skills_explorer";
import CustomValuesList from "PFComponents/custom_values_list/custom_values_list";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { Profile } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "../../../../parts/overview/detail_view/detail_view.module.scss";
import { Label } from "../parts/label";

const SKILLS_LIMIT = 5;

interface SkillsProps {
  profile: Profile;
}

export const Skills = ({ profile }: SkillsProps): JSX.Element => {
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("translation");
  const skillsExplorerAvailable = profile.skill_categories && profile.skill_categories.length > 0;
  const skillsType = currentProfile.custom_types.find(({ name }) => name === "skills");
  const { skills } = profile;
  return (
    <div className={css.item} style={{ height: 360 }}>
      <Label text={t("skills")} />
      {skillsExplorerAvailable ? (
        <SkillsExplorer
          profile={profile}
          skills={skills}
          selectedChart={currentProfile.preferences.display?.skills_insights || "bubble"}
        />
      ) : (
        <CustomValuesList
          type={skillsType}
          customValues={skills}
          showTips
          displayAttributeLevel
          profileId={profile.id}
          moreLimit={SKILLS_LIMIT}
          currentProfile={currentProfile}
          currentAccount={currentAccount}
          showModal
        />
      )}
    </div>
  );
};
