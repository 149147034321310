import isEmpty from "lodash/isEmpty";
import { useBookingSiblings } from "PFApp/booking/parts/overview/detail_view/booking_detail/booking_detail_content/booking_siblings/use_booking_siblings";
import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import { useBookingTemplate } from "PFCore/hooks/queries/bookings/booking_templates/use_booking_template";
import { Booking } from "PFTypes";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { truncateTitle } from "../booking_form_common_inputs";
import { useBookingFormContext } from "../booking_form_context_provider";
import { mapBookings } from "../booking_type_selector/map_bookings";
import { BookingDataItem, BookingDataItemLimitation, BookingFormMode } from "../use_booking_form";
import { useDefaultBookingsMapper } from "./use_default_bookings_mapper";

const INITIAL_BOOKINGS_FOR_EDIT_OR_CLONE_LOADING = "initial-bookings-for-edit-or-clone-loading";

type UseInitialBookingsForEdit = {
  initialBooking?: Booking;
  enabled: boolean;
  mode: BookingFormMode;
};

type UseInitialBookingsForEditReturn = {
  bookings: BookingDataItem[];
  loading: boolean;
};

export const useInitialBookingsForEditOrClone = ({
  initialBooking,
  enabled,
  mode
}: UseInitialBookingsForEdit): UseInitialBookingsForEditReturn => {
  const { t } = useTranslation("bookingModule");
  const { loadingState, setLoadingState } = useBookingFormContext();
  const [bookingItems, setBookingItems] = useState<BookingDataItem[]>();

  const mapCloneTitle = useCallback(
    (title?: string) => {
      const cloneTitle = t("bookings.clone.cloneBookingTitle", { title: title ?? "" });
      return truncateTitle(cloneTitle);
    },
    [t]
  );

  const isCloneMode = [BookingFormMode.CloneSingle, BookingFormMode.CloneAll].includes(mode);
  const { mapAPIBookings, mapBookingTemplate } = useDefaultBookingsMapper({
    withId: !isCloneMode,
    mapTitle: isCloneMode ? mapCloneTitle : undefined
  });
  const { isEnabled: isBMEnabled, isBookingResourcer } = useBookingModulePermissions();

  const isRepeatedBooking = !!initialBooking?.booking_template_id;

  const { data: siblingBookings, isFetching: isBookingSiblingsFetching } = useBookingSiblings({
    bookingId: initialBooking?.id,
    profileId: initialBooking?.profile_id,
    activityId: initialBooking?.activity_id ?? undefined,
    enabled: enabled && !isRepeatedBooking,
    forceFetchingSingle: mode === BookingFormMode.CloneSingle
  });

  const { data: bookingTemplate, isFetching: isBookingTemplateFetching } = useBookingTemplate(
    initialBooking?.booking_template_id,
    {
      enabled: enabled && isRepeatedBooking
    }
  );

  useEffect(() => {
    if (!enabled || !initialBooking) {
      return;
    }
    if (isRepeatedBooking) {
      if (bookingTemplate && !isBookingTemplateFetching) {
        const items = mapBookingTemplate(bookingTemplate);
        const bookings =
          isBMEnabled && isBookingResourcer
            ? items
            : mapBookings(items, { bookingTemplateId: undefined }, initialBooking);
        setBookingItems(
          bookings.filter(({ limitation }) =>
            isCloneMode ? limitation === BookingDataItemLimitation.None : true
          )
        );
      }
      return;
    } else {
      if (!isEmpty(siblingBookings) && !isBookingSiblingsFetching) {
        const items = mapAPIBookings(siblingBookings);
        setBookingItems(
          items.filter(({ limitation }) =>
            isCloneMode ? limitation === BookingDataItemLimitation.None : true
          )
        );
      }
      return;
    }
  }, [
    siblingBookings,
    bookingTemplate,
    enabled,
    initialBooking,
    isRepeatedBooking,
    isBookingTemplateFetching,
    mapBookingTemplate,
    isBMEnabled,
    isBookingResourcer,
    isCloneMode,
    isBookingSiblingsFetching,
    mapAPIBookings
  ]);

  useEffect(() => {
    if (enabled && (isBookingSiblingsFetching || isBookingTemplateFetching)) {
      setLoadingState((prev) => ({ ...prev, [INITIAL_BOOKINGS_FOR_EDIT_OR_CLONE_LOADING]: true }));
    } else {
      setLoadingState((prev) => ({ ...prev, [INITIAL_BOOKINGS_FOR_EDIT_OR_CLONE_LOADING]: false }));
    }
  }, [isBookingSiblingsFetching, isBookingTemplateFetching]);

  return {
    bookings: bookingItems ?? [],
    loading: loadingState[INITIAL_BOOKINGS_FOR_EDIT_OR_CLONE_LOADING]
  };
};
