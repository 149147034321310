import colors from "PFTheme/tokens/colors";

export const RADIUS = 5;

export const LINE_WT = RADIUS / 3;

const VIEW_BOX_HEIGHT = 2 * RADIUS;
const getViewBoxWidth = (experienceLevelsCount: number) =>
  2 * experienceLevelsCount * RADIUS + (experienceLevelsCount - 1) * LINE_WT;

export const HEIGHT_MIDDLE = Math.ceil(VIEW_BOX_HEIGHT / 2);

export const getCircleCenter = (circleIndex: number) => ({
  cx: 2 * RADIUS * circleIndex + LINE_WT * circleIndex + RADIUS,
  cy: HEIGHT_MIDDLE
});

export const getLinePosition = (circleIndex: number) => {
  const { cx: circleCenterX } = getCircleCenter(circleIndex);

  return {
    x1: circleCenterX - RADIUS - LINE_WT,
    x2: circleCenterX - RADIUS
  };
};

export const getViewBox = (experienceLevelsCount: number) => {
  const viewBoxWidth = getViewBoxWidth(experienceLevelsCount);

  return `0 0 ${viewBoxWidth} ${VIEW_BOX_HEIGHT}`;
};
export const getRequirementViewBox = (experienceLevelsCount: number) => {
  const viewBoxWidth = getViewBoxWidth(experienceLevelsCount);

  // for circles plus rectangles icon we want to cut space from top and bottom of rectangles
  return `0 ${VIEW_BOX_HEIGHT / 6} ${viewBoxWidth} ${VIEW_BOX_HEIGHT / 2}`;
};

export const RECT_WT = 2 * RADIUS;
export const RECT_HT = (2 * RADIUS) / 3;
export const RECT_BORDER_RADIUS = RECT_HT / 2;

export const THEMES = {
  light: {
    main: colors.paletteBlue0,
    secondary: colors.paletteNeutral0
  },
  dark: {
    main: colors.paletteWhite0,
    secondary: colors.paletteBlue2
  }
};
