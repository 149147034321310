import { useExperienceConfig } from "PFApp/hooks/use_experience_config";
import { Icon } from "PFComponents/icon";
import { Experience } from "PFTypes";

import { getViewBox, LINE_WT, RADIUS } from "../constants";

type NoExperienceProps = { experience: Experience; size: number };

export const NoExperience = ({ experience, size }: NoExperienceProps) => {
  const { experienceLevels } = useExperienceConfig();

  const index = experienceLevels.indexOf(experience);

  return (
    <svg height={size} viewBox={getViewBox(experienceLevels.length)}>
      <Icon name="cross" color="paletteRed0" size="xxs" x={index * (2 * RADIUS + LINE_WT)} />
    </svg>
  );
};
