import classNames from "classnames";
import FocusTrap from "focus-trap-react";
import { ActionIcon } from "PFComponents/action_icon";
import Calendar from "PFComponents/calendar/calendar";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { isActionKeyPressed } from "PFCore/helpers/is_action_key_pressed";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { EventKey } from "PFTypes/event_key";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import { useClickAway } from "react-use";

import css from "./inline_calendar.module.scss";

export const InlineCalendar = ({
  style,
  rootCalendarClassName,
  letClear,
  onRestore,
  handleChange,
  showTime,
  displayValue,
  selectedDate,
  label,
  locked,
  minDate,
  maxDate,
  lockedTip,
  disabled,
  qaId,
  error,
  warning,
  kind,
  placeholder,
  required,
  calendarProps,
  calendarStyle,
  popperOptions,
  shouldCloseOnSelection,
  portalRef,
  clearDate
}) => {
  const [selectedDay, setSelectedDay] = useState(selectedDate);
  const [showCalendar, setShowCalendar] = useState(false);

  const popperReferenceElement = useRef();
  const [popperElement, setPopperElement] = useState(null);
  const { dateFormatString, dateTimeFormatString, formatISODateTime, utc } = useDateFormatter();

  const menuRef = useRef();

  useEffect(() => {
    setSelectedDay(selectedDate);
  }, [selectedDate]);

  const renderValue = () => {
    if (displayValue) {
      return displayValue;
    } else {
      const fmt = showTime ? dateTimeFormatString : dateFormatString;
      return selectedDate ? utc(selectedDate).format(fmt) : "";
    }
  };

  const handleHideCalendar = () => {
    setShowCalendar(false);
  };

  const handleShowCalendar = () => {
    setShowCalendar(true);
  };

  useClickAway(portalRef ? portalRef : popperReferenceElement, handleHideCalendar);

  const handleCalendarChange = (date) => {
    if (shouldCloseOnSelection) {
      setSelectedDay(date);
      handleHideCalendar();
    }

    handleChange && handleChange(date);
  };

  const handleCalendarKeyDown = (event) => {
    if (event.key === EventKey.Escape) {
      handleHideCalendar();
    }
  };

  const handleInputKeyDown = (event) => {
    if (isActionKeyPressed(event)) {
      handleShowCalendar();
    } else if (event.key === EventKey.Escape) {
      handleHideCalendar();
    }
  };

  const value = renderValue();

  const { styles, attributes } = usePopper(popperReferenceElement.current, popperElement, {
    placement: "bottom",
    ...popperOptions
  });

  const CalendarRenderer = (
    <FocusTrap>
      <div
        role="dialog"
        className={css.calendar}
        ref={setPopperElement}
        style={{ ...calendarStyle, ...styles.popper, zIndex: 20000 }}
        {...attributes.popper}
      >
        <Calendar
          {...calendarProps}
          minDate={minDate}
          maxDate={maxDate}
          showTime={showTime}
          rootClassName={rootCalendarClassName}
          selectedDate={selectedDay}
          handleChange={handleCalendarChange}
          onKeyDown={handleCalendarKeyDown}
        />
      </div>
    </FocusTrap>
  );

  return (
    <div className={classNames(css.root)} style={style} ref={popperReferenceElement}>
      <div className={css.actions}>
        {onRestore && (
          <ActionIcon name="history" size="sm" onClick={onRestore} fill="var(--palettePrimary0)" />
        )}
        {value && letClear && (
          <ActionIcon
            name="filter-clean"
            size="sm"
            onClick={() => handleChange(clearDate ? clearDate : formatISODateTime())}
            fill="var(--palettePrimary0)"
          />
        )}
      </div>
      <div ref={menuRef}>
        <InputFieldSet
          aria-expanded={showCalendar}
          aria-haspopup="dialog"
          kind={kind}
          icon="calendar"
          label={label}
          value={value}
          locked={locked}
          lockedTip={lockedTip}
          disabled={disabled}
          qaId={qaId}
          error={error}
          placeholder={placeholder}
          required={required}
          warning={warning}
          onClick={handleShowCalendar}
          onKeyDown={handleInputKeyDown}
        />
        {showCalendar &&
          !disabled &&
          (portalRef?.current
            ? ReactDOM.createPortal(CalendarRenderer, portalRef.current)
            : CalendarRenderer)}
      </div>
    </div>
  );
};

InlineCalendar.propTypes = {
  ...InputFieldSet.propTypes,
  ...Calendar.propTypes,
  style: PropTypes.object,
  shouldCloseOnSelection: PropTypes.bool,

  handleChange: PropTypes.func.isRequired,
  saveRef: PropTypes.func,
  letClear: PropTypes.bool,
  onRestore: PropTypes.func,
  portalRef: PropTypes.string,
  clearDate: PropTypes.string,
  rootCalendarClassName: PropTypes.string,
  calendarProps: PropTypes.object,
  popperOptions: PropTypes.object
};

InlineCalendar.defaultProps = {
  ...Calendar.defaultProps,
  style: null,
  shouldCloseOnSelection: true,
  letClear: false,
  portalRef: null,
  clearDate: null
};
