import { useExperienceConfig } from "PFApp/hooks/use_experience_config";
import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { useMemo } from "react";

export const useSkillsProficiencyOptions = () => {
  const { experienceLevels } = useExperienceConfig();
  const experienceLabelMap = useExperienceLabelMap();

  return useMemo<DropdownOption[]>(
    () =>
      experienceLevels.map((experience) => ({
        id: experience,
        item: experience,
        displayElement: experienceLabelMap[experience]
      })),
    [experienceLabelMap, experienceLevels]
  );
};
