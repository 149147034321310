import classNames from "classnames";
import colors from "PFTheme/tokens/colors";
import { CustomValue, Experience } from "PFTypes";
import { Fragment, ReactNode, useEffect, useState } from "react";

import { getCircleCenter, getLinePosition, getViewBox } from "../../constants";
import { Line } from "../line";
import { Point } from "../point";
import css from "./rating_dots.module.scss";

export type RatingDotsProps = {
  customValue?: CustomValue;
  experience?: Experience | null;
  size?: number;
  viewBox?: string;
  className?: string;
  color?: string;
  colorSecondary?: string;
  shape?: "circle" | "rect";
  experienceLevels: Experience[];
  onChange?: (experience: Experience) => void;
  renderTooltip?: (experience: Experience) => ReactNode;
};

export const RatingDots = ({
  experience = null,
  size,
  className,
  color = colors.paletteBlue0,
  colorSecondary = colors.paletteNeutral0,
  shape = "circle",
  experienceLevels,
  viewBox = getViewBox(experienceLevels.length),
  onChange,
  renderTooltip
}: RatingDotsProps) => {
  const [experienceToShow, setExperienceToShow] = useState<Experience>(experience || Experience.None);
  useEffect(() => {
    setExperienceToShow(experience || 0);
  }, [experience]);

  const isCircle = shape === "circle";

  const handleMouseOut = () => setExperienceToShow(experience || 0);
  const handleMouseOver = (event) => {
    event.stopPropagation();
    setExperienceToShow(event.target.getAttribute("data-level"));
  };

  return (
    <svg
      className={classNames(css.ratingDots, className)}
      data-level={experience}
      height={size ?? "1em"}
      focusable="false"
      role="presentation"
      viewBox={viewBox}
      onMouseOut={onChange ? handleMouseOut : undefined}
      onMouseOver={onChange ? handleMouseOver : undefined}
    >
      {experienceLevels.map((experienceLevel, index) => {
        const isMainColor = experienceToShow >= experienceLevel;

        return (
          <Fragment key={experienceLevel}>
            {isCircle && index > 0 && (
              <Line
                experience={experienceLevel}
                {...getLinePosition(index)}
                color={isMainColor ? color : colorSecondary}
              />
            )}
            <Point
              shape={shape}
              {...getCircleCenter(index)}
              experience={experienceLevel}
              onChange={experience !== experienceLevel ? onChange : undefined}
              color={isMainColor ? color : colorSecondary}
              tooltipContent={renderTooltip?.(experienceLevel)}
            />
          </Fragment>
        );
      })}
    </svg>
  );
};
