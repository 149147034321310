import classNames from "classnames";
import { getProfileName } from "PFCore/helpers/profile";
import RightArrow from "PFIcons/right_arrow.svg";
import { AuditPlannerExceptionsDictionary, CamelizedShortlistProfile } from "PFTypes";
import { useTranslation } from "react-i18next";
import { humanize, titleize } from "underscore.string";

import { getExceptionDetails } from "../helpers";
import css from "../modals.module.scss";

type ReasonForExceptionProps = {
  exception?: AuditPlannerExceptionsDictionary;
  profile: CamelizedShortlistProfile;
  selectedIndex: number;
  onSelectedIndexChange: (selectedIndex: number) => void;
};

export const ReasonForException = ({
  exception,
  profile,
  selectedIndex,
  onSelectedIndexChange
}: ReasonForExceptionProps) => {
  const { t } = useTranslation("auditPlanner");

  const { availability, compliance, isMultiple, keys: exceptionKeys } = getExceptionDetails(exception);
  const exceptionKeySelected = exceptionKeys[selectedIndex];

  return (
    <>
      {isMultiple && (
        <div className={css.multipleExceptionReason}>
          <span className={css.bold}>{t(`modals.auditPlannerExceptions.${exceptionKeySelected}`)}</span>
          <span className={css.arrowBox}>
            <RightArrow
              className={classNames(css.arrow, {
                [css.disabled]: selectedIndex !== 1
              })}
              style={{ transform: "rotate(180deg)" }}
              onClick={() => onSelectedIndexChange(0)}
            />
            <span>
              {selectedIndex + 1}/{exceptionKeys.length}
            </span>
            <RightArrow
              className={classNames(css.arrow, {
                [css.disabled]: selectedIndex !== 0
              })}
              onClick={() => onSelectedIndexChange(1)}
            />
          </span>
        </div>
      )}

      <span style={{ fontWeight: isMultiple ? 300 : "inherit" }}>
        {exceptionKeySelected === "availability" &&
          availability?.isqmOverbooking &&
          availability?.isqmOverbooking?.length > 0 && (
            <div key="isqmOverbooking" className={css.text}>
              {Object.values(availability.isqmOverbooking).map(({ startDate, weeks }) => (
                <div key={startDate}>{t("modals.weekCommencingOn", { startDate, weeks })}</div>
              ))}
            </div>
          )}
        {exceptionKeySelected === "compliance" && (
          <div className={css.text}>
            {t("modals.reasonForExceptionProfileMissing", {
              name: getProfileName(profile),
              values: Object.entries(compliance || {})
                .map(([name, tags]) => `${titleize(humanize(name))}: ${tags.join(", ")}`)
                .join(", ")
            })}
          </div>
        )}
      </span>
    </>
  );
};
