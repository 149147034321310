import SimpleBadge from "PFComponents/badges_list/simple_badge";
import { Radios } from "PFComponents/radios/radios";
import { Id } from "PFTypes";
import { useMemo } from "react";

import { SkillsFrameworkCustomValueDecorated } from "../skills_framework_select_modal.types";
import css from "./conflict_radios.module.scss";

type ConflictRadiosProps = {
  id: Id;
  value?: Id;
  values: SkillsFrameworkCustomValueDecorated[];
  onChange: (value: Id) => void;
};
export const ConflictRadios = ({ id, value, values, onChange }: ConflictRadiosProps) => {
  const options = useMemo(
    () =>
      values.map(({ id, value, kind }) => ({
        id,
        customLabel: <SimpleBadge key={id} text={value} kind={kind} />
      })),
    [values]
  );

  return (
    <div className={css.container}>
      <Radios<Id>
        name={`conflict-${id}`}
        items={options}
        value={value || values[0]?.id}
        onChange={onChange}
        inline
        verticalItems
      />
    </div>
  );
};
