import useStorage from "PFCore/helpers/use_storage";
import { Filters, Value } from "PFTypes";

import { useFilters, UseFiltersReturn } from "./use_filters";

const STORAGE_OPTIONS = { expiresIn: 0 };

export type FiltersStorage = UseFiltersReturn;

const useStorageFilters = ({
  storagePrefix,
  defaultValue
}: {
  storagePrefix: string;
  defaultValue: Filters<Value>;
}): UseFiltersReturn => {
  const [selectedFilters, setSelectedFilters] = useStorage<Filters<Value>>(
    `${storagePrefix}_selectedValueFilters`,
    defaultValue,
    STORAGE_OPTIONS
  );

  return useFilters({ selectedFilters, setSelectedFilters, defaultValue });
};

export default useStorageFilters;
