import { ActionDiv } from "PFComponents/containers";
import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { Experience } from "PFTypes";
import React, { AriaAttributes, PropsWithChildren, useMemo } from "react";

import { ExperienceLevel, ExperienceLevelProps } from "../experience_level";
import { useCustomValueAriaLabel } from "./hooks/use_custom_value_aria_label";
import css from "./skill_pill.module.scss";

type SkillPillProps = Pick<ExperienceLevelProps, "theme" | "showTooltip"> & {
  name: string;
  experienceLevels: Experience[];
  customTypeAriaLabel?: string;
  experience?: Experience;
  requiredExperience?: Experience;
  isCore?: boolean;
  isDevelopmental?: boolean;
  isVerified?: boolean;
  isVerifiedCredly?: boolean;
  isVerifiedOthers?: boolean;
  isRequirement?: boolean;
  truncateLength?: number;
  onClick?: VoidFunction;
  experienceTooltipDisabled?: boolean;
  customIconName?: "check" | "cross";
};

const ClickableWrapper = ({
  children,
  onClick,
  "aria-label": ariaLabel
}: PropsWithChildren<Pick<SkillPillProps, "onClick">> & AriaAttributes) => (
  <ActionDiv onClick={onClick} tabIndex={0} aria-label={ariaLabel} className={css.actionDiv}>
    {children}
  </ActionDiv>
);

export const SkillPill = ({
  name,
  experienceLevels,
  customTypeAriaLabel,
  isCore,
  isDevelopmental,
  isVerified,
  isVerifiedCredly,
  isVerifiedOthers,
  isRequirement,
  truncateLength = 40,
  onClick,
  theme,
  experience,
  requiredExperience,
  showTooltip = true,
  customIconName
}: SkillPillProps) => {
  const ariaLabel = useCustomValueAriaLabel({
    name,
    customTypeAriaLabel,
    isRequirement,
    experience,
    requiredExperience
  });
  const Wrapper = useMemo(() => (onClick ? ClickableWrapper : React.Fragment), [onClick]);

  const isNameTooLong = name.length > truncateLength;

  return (
    <Wrapper onClick={onClick} aria-label={ariaLabel}>
      <span className={css.skillPill}>
        {(experience || requiredExperience) && (
          <ExperienceLevel
            theme={theme}
            isRequirement={isRequirement}
            experience={experience}
            requiredExperience={requiredExperience}
            showTooltip={showTooltip}
            experienceLevels={experienceLevels}
          />
        )}
        {customIconName && (
          <Icon
            name={customIconName}
            color={customIconName === "cross" ? "paletteRed0" : undefined}
            size={customIconName === "cross" ? "sm" : undefined}
          />
        )}
        <Tooltip content={<Typography variant="labelButton">{name}</Typography>} disabled={!isNameTooLong}>
          <div className={css.nameWrapper} title={name}>
            <Typography variant="labelButton" className={css.name}>
              {name.slice(0, truncateLength)}
              {isNameTooLong && "..."}
            </Typography>
          </div>
        </Tooltip>
        {isCore && <Icon name="core" />}
        {isDevelopmental && <Icon name="development" />}
        {isVerified && <Icon name={isVerifiedCredly ? "verified-credly" : "verified"} />}
        {isVerifiedOthers && <Icon name="verified-others" />}
      </span>
    </Wrapper>
  );
};
