import { ColumnHelper } from "@tanstack/react-table";
import { Button } from "PFComponents/button";
import { EmptyState } from "PFComponents/empty_state";
import { LoadingDots } from "PFComponents/loading_dots";
import Table from "PFComponents/table";
import { Typography } from "PFComponents/typography";
import { useActivityEconomics } from "PFCore/hooks/queries/activity/use_activity_economics";
import { convertToPercentage } from "PFCore/utilities/math";
import { Currency, EconomicsScenario } from "PFTypes";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActivityPageContext } from "../../activity_page_context";
import { ScenarioNameModal } from "../scenario_name_modal";
import { useEconomicsPermissions } from "../use_economics_permissions";
import { useScenarioNavigate } from "../use_scenario_navigate";
import css from "./economics_list.module.scss";
import { ScenarioActions } from "./scenario_actions";

export const EconomicsList = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics" });

  const { openScenario } = useScenarioNavigate();
  const { canCreateScenarios } = useEconomicsPermissions();
  const { task: activity } = useActivityPageContext();

  const { data, isFetching } = useActivityEconomics(activity.id);

  const [isNewScenarioModalOpen, setIsNewScenarioModalOpen] = useState(false);

  const columnsGenerator = useCallback(
    (columnHelper: ColumnHelper<EconomicsScenario>) => [
      columnHelper.accessor(({ name }) => name, {
        id: "name",
        header: t("columns.name"),
        cell: (info) => (
          <Typography className={css.scenarioName} variant="bodyButtons" noMargin clipOverflow>
            {info.getValue()}
          </Typography>
        )
      }),
      columnHelper.accessor(({ vacanciesCoveredPercentage }) => vacanciesCoveredPercentage, {
        id: "vacanciesCovered",
        header: () => <span className={css.headerRightAlign}>{t("columns.vacanciesCovered")}</span>,
        cell: (info) => (
          <span className={css.cellRightAlign}>
            {convertToPercentage(info.getValue(), { isNormalized: true })}
          </span>
        )
      }),
      columnHelper.accessor(({ hoursCoveredPercentage }) => hoursCoveredPercentage, {
        id: "hoursCovered",
        header: () => <span className={css.headerRightAlign}>{t("columns.hoursCovered")}</span>,
        cell: (info) => (
          <span className={css.cellRightAlign}>
            {convertToPercentage(info.getValue(), { isNormalized: true })}
          </span>
        )
      }),
      columnHelper.accessor(({ skillsCoveredPercentage }) => skillsCoveredPercentage, {
        id: "skillsCovered",
        header: () => <span className={css.headerRightAlign}>{t("columns.skillsCovered")}</span>,
        cell: (info) => (
          <span className={css.cellRightAlign}>
            {convertToPercentage(info.getValue(), { isNormalized: true })}
          </span>
        )
      }),
      columnHelper.accessor(({ cost }) => cost, {
        id: "cost",
        header: () => <span className={css.headerRightAlign}>{t("columns.cost")}</span>,
        cell: (info) => {
          let cost;
          if (!info.getValue()) {
            cost = "-";
          } else {
            const { value, currency } = info.getValue();
            const symbol = currency ? Currency[currency] : "";
            cost = `${symbol}${value}`;
          }

          return <span className={css.cellRightAlign}>{cost}</span>;
        }
      }),
      columnHelper.accessor((row) => row, {
        id: "actions",
        header: "",
        cell: (info) => {
          const scenario = info.getValue();

          return (
            <span className={css.cellRightAlign}>
              <ScenarioActions activity={activity} scenario={scenario} onClone={openScenario} />
            </span>
          );
        },
        enableSorting: false,
        size: 64
      })
    ],
    [t, activity, openScenario]
  );
  if (isFetching) {
    return (
      <article className={css.loader}>
        <LoadingDots circleSize="xl" circlesEnabled />
      </article>
    );
  }

  return (
    <main className={css.root}>
      <section className={css.header}>
        <Typography variant="h3" noMargin>
          {t("header", { count: data?.entries.length || 0 })}
        </Typography>
        {canCreateScenarios && (
          <Button icon="add" text={t("addScenario")} onClick={() => setIsNewScenarioModalOpen(true)} />
        )}
      </section>
      <section>
        {!data?.entries.length ? (
          <EmptyState
            title={t("noScenarios")}
            message={canCreateScenarios ? t("getStarted") : undefined}
            buttonIcon="add"
            buttonLabel={t("addScenario")}
            onButtonClick={canCreateScenarios ? () => setIsNewScenarioModalOpen(true) : undefined}
          />
        ) : (
          <Table<EconomicsScenario>
            tableData={data?.entries || []}
            generateColumns={columnsGenerator}
            onRowClick={(row) => openScenario(row.id)}
          />
        )}
      </section>
      {isNewScenarioModalOpen && (
        <ScenarioNameModal
          onSave={openScenario}
          activityId={activity.id}
          onClose={() => setIsNewScenarioModalOpen(false)}
        />
      )}
    </main>
  );
};
