import { Icon, IconProps } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { ProfileCustomValue } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./skill.module.scss";

type UseSkillActionsOptions = {
  skill: Partial<ProfileCustomValue>;
  addToLog?: (...changes: any[]) => void;
  coreSkillsCount: number;
  developmentalSkillsCount: number;
  portalElement?: HTMLDivElement;
};

type ActionOptionProps = {
  label: string;
  iconProps: IconProps;
};

const ActionOption = ({ label, iconProps }: ActionOptionProps) => (
  <div className={css.skillAction}>
    <Icon {...iconProps} />
    <Typography variant="bodyRegular" noMargin>
      {label}
    </Typography>
  </div>
);

export const useSkillActionsOptions = ({
  skill,
  addToLog,
  coreSkillsCount,
  developmentalSkillsCount,
  portalElement
}: UseSkillActionsOptions) => {
  const { t: tTranslation } = useTranslation();
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.skillsModal" });
  const { data: currentAccount } = useCurrentAccount();
  const {
    config: {
      profile: { developmental_skills_limit: developmentalSkillsLimit, top_skills_limit: coreSkillsLimit }
    }
  } = currentAccount;

  const isMarkAsCoreDisabled = coreSkillsCount >= coreSkillsLimit && !skill.top;
  const isMarkAsDevelopmentalDisabled =
    developmentalSkillsCount >= developmentalSkillsLimit && !skill.developmental;

  const actionOptions = useMemo(
    () => [
      {
        id: "tag_as_core",
        disabled: !skill.experience || isMarkAsCoreDisabled,
        item: () =>
          skill.experience && addToLog?.({ id: skill.id, data: { top: !skill.top }, type: "ranked" }),
        displayElement: (
          <Tooltip
            disabled={!isMarkAsCoreDisabled}
            content={tTranslation("coreSkillsLimit", {
              limit: coreSkillsLimit
            })}
            appendTo={portalElement}
          >
            <div className={css.optionTooltipContent}>
              <ActionOption
                iconProps={{ name: "core" }}
                label={skill.top ? t("untagAsCoreSkill") : t("tagAsCoreSkill")}
              />
            </div>
          </Tooltip>
        )
      },
      {
        id: "tag_as_developmental",
        disabled: !skill.experience || isMarkAsDevelopmentalDisabled,
        item: () =>
          skill.experience &&
          addToLog?.({
            id: skill.id,
            data: {
              developmental: !skill.developmental
            },
            type: "ranked"
          }),
        displayElement: (
          <Tooltip
            disabled={!isMarkAsDevelopmentalDisabled}
            content={tTranslation("developmentalSkillsLimit", {
              limit: developmentalSkillsLimit
            })}
            appendTo={portalElement}
          >
            <div className={css.optionTooltipContent}>
              <ActionOption
                iconProps={{ name: "development" }}
                label={skill.developmental ? t("untagAsDevelopmentalSkill") : t("tagAsDevelopmentalSkill")}
              />
            </div>
          </Tooltip>
        )
      },
      {
        id: "delete",
        item: () =>
          addToLog?.({
            id: skill.id,
            data: { experience: 0, interest: false, top: false, developmental: false },
            type: "delete"
          }),
        displayElement: (
          <ActionOption
            iconProps={{ name: "cross", size: "xs", color: "paletteRed0" }}
            label={t("deleteSkill")}
          />
        )
      }
    ],
    [
      addToLog,
      coreSkillsLimit,
      developmentalSkillsLimit,
      isMarkAsCoreDisabled,
      isMarkAsDevelopmentalDisabled,
      portalElement,
      skill.developmental,
      skill.experience,
      skill.id,
      skill.top,
      t,
      tTranslation
    ]
  );

  return { actionOptions };
};
