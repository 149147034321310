import classNames from "classnames";
import { findIndex } from "lodash";
import { Button } from "PFComponents/button";
import DropDown from "PFComponents/dropdown/dropdown";
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useClickAway } from "react-use";

import css from "./button_select.module.scss";

const ButtonSelect = ({
  classes = {},
  title,
  onChange,
  kind,
  qaId,
  options,
  selectedId,
  displayAbove,
  preserveDropdown,
  disabled,
  icon
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef();
  const handleClose = () => setShowDropdown(false);
  useClickAway(ref, handleClose);

  const handleChange = (value) => {
    !preserveDropdown && setShowDropdown(false);
    onChange(value);
  };

  const selectedIndex = findIndex(options, { id: selectedId });

  return (
    <div className={classNames(css.root, classes.root)} ref={ref}>
      <Button
        kind={kind}
        qaId={qaId}
        onClick={() => setShowDropdown((prev) => !prev)}
        className={classes.button}
        disabled={disabled}
        icon={icon}
      >
        {title}
      </Button>
      {showDropdown && (
        <DropDown
          rootClassName={classNames(css.dropdown, classes.dropDown)}
          selectedIndex={selectedIndex}
          options={options}
          handleChange={handleChange}
          handleClose={handleClose}
          displayAbove={displayAbove}
        />
      )}
    </div>
  );
};

ButtonSelect.propTypes = {
  kind: PropTypes.oneOf(["primary", "secondary", "secondary", "autosuggest"]),
  qaId: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    button: PropTypes.string,
    dropDown: PropTypes.string
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      displayElement: PropTypes.node.isRequired,
      item: PropTypes.any.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedId: PropTypes.string,
  title: PropTypes.oneOfType(["string", "Element"]),
  displayAbove: PropTypes.bool,
  preserveDropdown: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string
};

export default ButtonSelect;
