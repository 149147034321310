import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { fetchProfileWarnings } from "PFApp/booking/services/api";
import { Warnings } from "PFApp/booking/types";
import { CalendarRange } from "PFTypes";
import { useMemo } from "react";

import { ActivityWarningsIndex } from "../activity";
import profileKeys from "./query_keys";

export type ProfileWarningsIndex = Record<number, Warnings>;

const groupLoadingByRowKey = (
  queries: UseQueryResult<ActivityWarningsIndex>[],
  profiles: Omit<Profile, "activityId">[]
): Record<string, boolean> =>
  queries.reduce((result, { isLoading, isFetching }, index) => {
    const { rowKey } = profiles[index];
    if (!rowKey) {
      return result;
    }
    return {
      ...result,
      [rowKey]: isLoading || isFetching
    };
  }, {});

type Profile = {
  id: number;
  rowKey?: string;
  activityId?: number;
};

type UseProfilesWarnings = {
  profiles: Profile[];
  calendarRange?: CalendarRange;
  enabled?: boolean;
};
type UseProfilesWarningsReturn = {
  warningsByProfile: ProfileWarningsIndex;
  profileWarningsLoadingByRowKey: Record<string, boolean>;
  isLoading: boolean;
};

export const useProfilesWarnings = ({
  profiles,
  calendarRange,
  enabled = true
}: UseProfilesWarnings): UseProfilesWarningsReturn => {
  const stringifiedProfiles = JSON.stringify(profiles);
  const queriesConfig = useMemo(
    () =>
      profiles.map((profile) => ({
        queryKey: profileKeys.warnings(profile.id, calendarRange, profile.activityId),

        queryFn: async (): Promise<ProfileWarningsIndex> => ({
          [profile.id]: await fetchProfileWarnings({
            profileId: profile.id,
            dateRange: calendarRange,
            activityId: profile.activityId
          })
        }),
        enabled
      })),
    [stringifiedProfiles, calendarRange, enabled]
  );
  const queries = useQueries({
    queries: queriesConfig
  });

  const isLoading = useMemo(
    () => !!queries.find(({ isLoading, isFetching }) => isLoading || isFetching),
    [queries]
  );

  const profileWarningsLoadingByRowKey = useMemo<Record<string, boolean>>(
    () => groupLoadingByRowKey(queries, profiles),
    [isLoading, stringifiedProfiles]
  );

  const warningsByProfile = useMemo<ProfileWarningsIndex>(
    () => Object.assign({}, ...queries.map(({ data }) => data)),
    [isLoading, stringifiedProfiles]
  );

  return {
    warningsByProfile,
    isLoading,
    profileWarningsLoadingByRowKey
  };
};
