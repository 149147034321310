import classNames from "classnames";
import snakeCase from "lodash/snakeCase";
import { DocumentButtons } from "PFApp/components/document_buttons";
import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import SimpleBadge from "PFCore/components/badges_list/simple_badge";
import { agoFormat } from "PFCore/helpers/date";
import { useNotificationMarkAsRead } from "PFCore/hooks/queries/notifications/use_notification_mark_as_read";
import { Notification } from "PFTypes";
import { DocFormat } from "PFTypes/doc_format";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import css from "./notification_item.module.scss";

export const getBadgeColour = (categoryType) => titleToBadgeColours[snakeCase(categoryType)] ?? "highlight";

const titleToBadgeColours = {
  new_role_filled: "light", // eslint-disable-line camelcase
  new_role_rejected: "danger", // eslint-disable-line camelcase
  role_filled: "light", // eslint-disable-line camelcase
  role_closed: "danger", // eslint-disable-line camelcase
  shortlist_share: "highlight", // eslint-disable-line camelcase
  skill_suggestion: "highlight", // eslint-disable-line camelcase
  shortlist_assigned: "success", // eslint-disable-line camelcase
  custom_value_close_to_expire: "highlight", // eslint-disable-line camelcase
  interested_role_closed: "danger" // eslint-disable-line camelcase
};

type NotificationItem = {
  notification: Notification;
};

export const NotificationItem = ({ notification }: NotificationItem) => {
  const { id, title, createdAt, categoryType, body, status, urlAddress } = notification;

  const { mutate: markAsRead, isLoading } = useNotificationMarkAsRead();

  const { t } = useTranslation();
  const isUnread = status === "unread";
  const badgeColour = useMemo(() => getBadgeColour(categoryType), [categoryType]);

  const renderLink = useCallback(
    ({ url, onClick }) => (
      <Link to={url} className={css.link} key={id} onClick={onClick}>
        <div className={css.body}>{body}</div>
      </Link>
    ),
    [body, id]
  );

  const handleMarkAsRead = () => isUnread && markAsRead({ id });

  return (
    <div className={classNames(css.notificationItem, { [css.read]: !isUnread })}>
      <div className={css.header}>
        <Typography variant="labelRegular" className={css.notificationLabel}>
          {agoFormat(createdAt)}
        </Typography>
        {isUnread ? (
          <Button
            kind="text"
            small
            onClick={() => markAsRead({ id })}
            text={t("notifications.markRead")}
            disabled={isLoading}
          />
        ) : (
          <Typography tag="span" variant="labelBold" className={css.notificationLabel}>
            {t("notifications.read")}
          </Typography>
        )}
      </div>
      <SimpleBadge
        text={title}
        kind={badgeColour}
        style={{
          margin: "0 0 10px 0",
          fontSize: 10,
          padding: "2px 10px",
          minHeight: 0,
          width: "fit-content"
        }}
      />
      {categoryType === "File Generated" ? (
        <DocumentButtons
          generateDocument={() => Promise.resolve({ fileUrl: urlAddress })}
          exportOptions={[DocFormat.Csv]}
          buttonComponent={({ onClick }) =>
            renderLink({
              url: "#",
              onClick: () => {
                onClick();
                handleMarkAsRead();
              }
            })
          }
          hideLabel
        />
      ) : (
        renderLink({ url: urlAddress, onClick: handleMarkAsRead })
      )}
    </div>
  );
};
