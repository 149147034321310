import moment from "moment";
import { Icon } from "PFComponents/icon";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./use_sorting_options.module.scss";

export const DEFAULT_SORTING_OPTION = "alphabetical-asc";

export const useSortingOptions = () => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts" });

  const skillsSortingOptions = useMemo(
    () => [
      {
        id: 0,
        item: "alphabetical-asc",
        displayElement: (
          <span className={css.dropdownItem}>
            {t("skillsModal.alphabetical")}
            <Icon name="caret-up" size="xs" />
          </span>
        ),
        sortFunc: (s1, s2) => {
          const v1 = s1.value || s1.text;
          const v2 = s2.value || s2.text;
          return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
        }
      },
      {
        id: 1,
        item: "alphabetical-desc",
        displayElement: (
          <span className={css.dropdownItem}>
            {t("skillsModal.alphabetical")}
            <Icon name="caret-down" size="xs" />
          </span>
        ),
        sortFunc: (s1, s2) => {
          const v1 = s1.value || s1.text;
          const v2 = s2.value || s2.text;
          return v1 < v2 ? 1 : v1 > v2 ? -1 : 0;
        }
      },
      {
        id: 2,
        item: "date-added-asc",
        displayElement: (
          <span className={css.dropdownItem}>
            {t("skillsModal.dateAdded")}
            <Icon name="caret-up" size="xs" />
          </span>
        ),
        sortFunc: (s1, s2) => {
          const v1 = moment(s1.created_at).valueOf();
          const v2 = moment(s2.created_at).valueOf();
          return v1 < v2 ? 1 : v1 > v2 ? -1 : 0;
        }
      },
      {
        id: 3,
        item: "date-added-desc",
        displayElement: (
          <span className={css.dropdownItem}>
            {t("skillsModal.dateAdded")}
            <Icon name="caret-down" size="xs" />
          </span>
        ),
        sortFunc: (s1, s2) => {
          const v1 = moment(s1.created_at).valueOf();
          const v2 = moment(s2.created_at).valueOf();
          return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
        }
      }
    ],
    [t]
  );

  return {
    skillsSortingOptions
  };
};
