import Color from "color";
import { isNumber, maxBy } from "lodash";
import { useThresholds } from "PFCore/hooks/queries/bookings/thresholds/use_thresholds";
import { useMemo } from "react";

import { DEFAULT_OVERBOOKING_COLOR } from "../../parts/helpers";
import { Overbooking } from "../../types";

type UseOverbookingColorProps = { overbookings: Pick<Overbooking, "utilization">[] };
type UseOverbookingColorReturn = {
  overbookingColor: Color<string> | undefined;
};

export const useOverbookingColor = ({
  overbookings
}: UseOverbookingColorProps): UseOverbookingColorReturn => {
  const thresholds = useThresholds(["overbooking"]);

  const overbookingMaxLoad = useMemo(
    () => maxBy(overbookings || [], "utilization")?.utilization,
    [overbookings]
  );

  const overbookingColor = useMemo(() => {
    const backgroundColor: string | undefined =
      (isNumber(overbookingMaxLoad) &&
        thresholds?.overbooking?.find(
          (item) => overbookingMaxLoad * 100 >= item.min && overbookingMaxLoad * 100 <= item.max
        )?.color) ||
      undefined;

    return backgroundColor ? new Color(backgroundColor) : new Color(DEFAULT_OVERBOOKING_COLOR);
  }, [overbookingMaxLoad]);

  return { overbookingColor };
};
