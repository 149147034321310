import { LoadingDots } from "PFComponents/loading_dots";
import SidePanel from "PFComponents/side_panel/side_panel";
import { Filters, Meta, Value } from "PFTypes";
import { PageTarget } from "PFTypes/saved_filters";
import { JSX, useRef } from "react";

import { FiltersContextProvider } from "../context/filters_context";
import { useFiltersContext } from "../context/filters_context";
import { AutoRefreshWarning } from "./auto_refresh_warning";
import { FiltersContent, FiltersContentProps } from "./filters_content";
import { ExternalFiltersProps, FiltersBaseProps } from "./filters_element";
import { FiltersFooter } from "./filters_footer";
import { FiltersHeader } from "./filters_header";
import css from "./filters_panel.module.scss";
import { AccordionProps, PagePrefix } from "./filters_panel.types";

export type FiltersPanelProps = {
  onFiltersChange: (
    filters: Filters<Value> | ((filters: Filters<Value>) => Filters<Value>),
    metaFilters?: Meta["filters"]
  ) => void;
  clearFilters?: (params?: any) => void;
  onClearTempFilters?: (params?: any) => void;
  closeFilters?: VoidFunction;
  blockedList?: string[];
  blockedChildrenList?: string[];
  filtersAreClear?: boolean;
  areExternalFiltersClear?: boolean;
  allowToClose?: boolean;
  accordionProps?: AccordionProps;
  isLoading?: boolean;
  keyPrefix: PagePrefix;
  viewsKey?: PageTarget | null;
  classes?: {
    header?: string;
    list?: string;
  };
  letRestoreAll?: boolean;
  restoreAllTooltipContent?: React.ReactNode;
  isRelevantToMeDisabled?: boolean;
  portalRef?: React.RefObject<HTMLDivElement>;
  disabled?: boolean;
  disabledLabel?: string;
  isChildrenEnabledDefault?: boolean;
  onApplyChanges?: VoidFunction;
  childrenPosition?: "top" | "bottom";
} & FiltersBaseProps &
  ExternalFiltersProps;

const FiltersContentWrapped = (props: FiltersContentProps) => {
  const { meta, handleFilterChange: onFilterChange } = useFiltersContext();

  return (
    <>
      {props.isLoading && (
        <div className={css.loadingOverlay}>
          <LoadingDots circlesEnabled circleSize="lg" />
        </div>
      )}
      <FiltersContent
        {...props}
        lastFocusedFilterNameRef={props.lastFocusedFilterNameRef}
        meta={meta}
        onFilterChange={onFilterChange}
      />
    </>
  );
};

export const FiltersPanel = (props: React.PropsWithChildren<FiltersPanelProps>): JSX.Element => {
  const {
    keyPrefix,
    meta,
    onFiltersChange,
    onFilterChange,
    clearFilters,
    onClearTempFilters,
    closeFilters,
    filtersAreClear,
    areExternalFiltersClear,
    allowToClose = true,
    viewsKey = null,
    classes = {},
    letRestoreAll,
    restoreAllTooltipContent,
    isRelevantToMeDisabled,
    disabled,
    disabledLabel,
    blockedList,
    blockedChildrenList,
    isChildrenEnabledDefault,
    onApplyChanges
  } = props;

  const lastFocusedFilterNameRef = useRef(null);

  return (
    <FiltersContextProvider
      keyPrefix={keyPrefix}
      meta={meta}
      onFilterChange={onFilterChange}
      onFiltersChange={onFiltersChange}
      clearFilters={clearFilters}
      onClearTempFilters={onClearTempFilters}
      filtersAreClear={filtersAreClear}
      areExternalFiltersClear={areExternalFiltersClear}
      blockedList={blockedList}
      blockedChildrenList={blockedChildrenList}
      isChildrenEnabledDefault={isChildrenEnabledDefault}
      onApplyChanges={onApplyChanges}
    >
      <SidePanel
        title={
          <FiltersHeader
            className={classes.header}
            disabled={disabled}
            disabledLabel={disabledLabel}
            lastFocusedFilterNameRef={lastFocusedFilterNameRef}
            letRestoreAll={letRestoreAll}
            restoreAllTooltipContent={restoreAllTooltipContent}
          />
        }
        show
        fullHeight
        size="large"
        onClose={closeFilters}
        footerRenderer={() => (
          <FiltersFooter
            viewsKey={viewsKey}
            onFiltersChange={onFiltersChange}
            isRelevantToMeDisabled={isRelevantToMeDisabled}
            allowToClose={allowToClose}
            closeFilters={closeFilters}
          />
        )}
      >
        <AutoRefreshWarning />
        <FiltersContentWrapped {...props} lastFocusedFilterNameRef={lastFocusedFilterNameRef} />
      </SidePanel>
    </FiltersContextProvider>
  );
};
