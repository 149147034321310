import { IconSize, IconSizeTable } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import colors from "PFTheme/tokens/colors";
import { CustomValue, Experience } from "PFTypes";
import { useCallback } from "react";

import { getRequirementViewBox, THEMES } from "./constants";
import css from "./experience_level.module.scss";
import { ExperienceLevelTooltipContent } from "./parts/experience_level_tooltip_content";
import { NoExperience } from "./parts/no_experience";
import { RatingDots, RatingDotsProps } from "./parts/rating_dots/rating_dots";

export type ExperienceLevelProps = Omit<RatingDotsProps, "color" | "size" | "shape"> & {
  isRequirement?: boolean;
  requiredExperience?: Experience | null;
  showTooltip?: boolean;
  customValue?: CustomValue;
  size?: IconSize;
} & ({ color?: never; theme?: "dark" | "light" } | { color?: RatingDotsProps["color"]; theme?: never });

export const ExperienceLevel = ({
  showTooltip = true,
  isRequirement,
  experience,
  requiredExperience,
  onChange,
  color,
  theme,
  size = "xxs",
  customValue,
  experienceLevels,
  ...ratingDotsProps
}: ExperienceLevelProps) => {
  const { main, secondary } = theme ? THEMES[theme] : { ...THEMES.light, main: color };
  const showLevelWithRequirement = !isRequirement && !!requiredExperience;
  const showNoExperience = !isRequirement && !experience && requiredExperience;
  const height = IconSizeTable[size];
  const shape = isRequirement ? "rect" : "circle";

  const renderTooltip = useCallback(
    (experience: Experience) => (
      <ExperienceLevelTooltipContent
        shape={shape}
        experience={experience}
        customValue={customValue}
        experienceLevels={experienceLevels}
      />
    ),
    [customValue, shape, experienceLevels]
  );

  const content = (
    <>
      {showNoExperience ? (
        <NoExperience experience={requiredExperience} size={height} />
      ) : (
        <RatingDots
          shape={shape}
          experience={(isRequirement && requiredExperience) || experience}
          size={height}
          onChange={onChange}
          color={main}
          colorSecondary={secondary}
          renderTooltip={!showTooltip && !requiredExperience && onChange ? renderTooltip : undefined}
          experienceLevels={experienceLevels}
          {...ratingDotsProps}
        />
      )}
      {showLevelWithRequirement && (
        <RatingDots
          shape="rect"
          size={height / 2}
          viewBox={getRequirementViewBox(experienceLevels.length)}
          experience={requiredExperience}
          color={showNoExperience ? colors.paletteRed0 : main}
          colorSecondary={secondary}
          experienceLevels={experienceLevels}
          {...ratingDotsProps}
        />
      )}
    </>
  );

  if (!showTooltip && !showLevelWithRequirement) {
    return content;
  }

  return (
    <Tooltip
      theme="pf-dark-blue"
      content={
        <ExperienceLevelTooltipContent
          shape={shape}
          experience={experience}
          requiredExperience={requiredExperience}
          customValue={customValue}
          experienceLevels={experienceLevels}
        />
      }
      disabled={!showTooltip || !!(!requiredExperience && onChange)}
    >
      <div className={css.experienceLevel}>{content}</div>
    </Tooltip>
  );
};
