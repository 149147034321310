import { UseFiltersReturn } from "PFApp/use_filtered_collection/use_filters";
import { ProjectsResponse } from "PFCore/hooks/queries/bookings/projects/use_projects_entries";
import { WorkforceResponse } from "PFCore/hooks/queries/bookings/workforce/use_workforce_entries";
import { Filters, OrderOption, Value } from "PFTypes";
import { Dispatch, SetStateAction } from "react";

import { CalendarView } from "./calendar_view";

export enum CalendarMode {
  Workforce = "workforce",
  Projects = "projects"
}

export type PinnedData<T extends ProjectsResponse | WorkforceResponse> = {
  data: T | undefined;
  pinnedIds: number[];
  setPinnedIds: Dispatch<SetStateAction<number[]>>;
  loading: boolean;
};

export type CalendarModeData<
  T extends ProjectsResponse | WorkforceResponse = ProjectsResponse | WorkforceResponse
> = {
  data: T & { loading: boolean };
  loaded: boolean;
  noOrderSelected: boolean;
  onOrderChange: React.Dispatch<SetStateAction<OrderOption>>;
  pinnedData?: PinnedData<T>;
  previousSearchId?: number;
} & Omit<UseFiltersReturn, "selectedFilters" | "areFiltersClear" | "clearFilters">;

export type CalendarModeInitialData = {
  filters: Filters<Value>;
  view?: CalendarView;
};
