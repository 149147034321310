import api from "PFCore/api";
import { Experience } from "PFTypes";

export type ProficiencyLevel = {
  numericalValue: Experience;
  displayAs: string;
  definition: string;
};

export const fetchProficiencyLevels = () =>
  api<ProficiencyLevel[]>({
    url: "proficiency_levels"
  });
