import classNames from "classnames";
import { debounce, filter, find, findIndex, isNumber, isString, map, reject, uniqBy } from "lodash";
import { NO_ITEM } from "PFComponents/dropdown/dropdown_item";
import { Select } from "PFComponents/select/select";
import i18n from "PFCore/i18n";
import PropTypes from "prop-types";
import { Component, createRef } from "react";

import canonicalId from "../../helpers/canonicalId";
import { LoadingDots } from "../loading_dots";
import css from "./autoselect.module.scss";
import { AutoSelectValues } from "./parts";

const canonicalValue = (item) => ({
  id: canonicalId(item.text || item.value),
  displayElement: item.text || item.value,
  item: item
});

class AutoSelect extends Component {
  // eslint-disable-next-line react/sort-comp
  queryOptions = () => {
    const { query, cache } = this.props;
    const { inputValue } = this.state;

    if (this.isUnmounted) {
      return;
    }

    const term = canonicalId(inputValue || "");

    if (this.xhr) {
      this.xhr.abort && this.xhr.abort();
      this.xhr = null;
    }

    if (this.cachedOptions[term]) {
      this.setState({ dropDownOptions: this.cachedOptions[term] });
      return;
    }

    this.setState(
      {
        dropDownOptions: [{ id: "loading", displayElement: <LoadingDots />, item: NO_ITEM }]
      },
      () => {
        this.xhr = query(term);
        this.xhr
          .then((resp) => {
            if (this.isUnmounted) {
              return;
            }

            const options = this.parseResponse(resp);

            if (cache === true) {
              this.cachedOptions[term] = options;
            }

            this.setState({ dropDownOptions: options, loaded: true });
          })
          .catch((error) => {
            this.setState({ dropDownOptions: [], loaded: true });
            this.props.onQueryError?.(error);
          });
      }
    );
  };

  constructor(props) {
    super(props);

    this.props.saveRef && this.props.saveRef(this);
    this.selectRef = createRef();
    this.autoSelectRef = createRef();
    this.handleMouseDown = this.handleMouseDown.bind(this);

    this.state = {
      inputValue: "",
      dropDownOptions: [],
      loaded: false,
      values: (this.props.values || []).map((value) => canonicalValue(value))
    };

    this.cachedOptions = {};
    this.debouncedQueryOptions = debounce(this.queryOptions, this.props.debounceWait);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleMouseDown);
  }

  componentDidUpdate(prevProps) {
    if (this.props.values !== prevProps.values) {
      this.setState({ values: (this.props.values || []).map((value) => canonicalValue(value)) });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
    document.removeEventListener("mousedown", this.handleMouseDown);
  }

  handleMouseDown(event) {
    const modalSelector = document.getElementById("modal_region");

    if (modalSelector && modalSelector.contains(event.target)) {
      return;
    }

    const isFocused =
      !this.autoSelectRef || !this.autoSelectRef.current || this.autoSelectRef.current.contains(event.target);

    this.setState({ isFocused });
  }

  setValues = (values) => {
    this.setState({ values: (values || []).map((value) => canonicalValue(value)) });
  };

  filterOptions = () => {
    const { multi, allowSameValueInOptions } = this.props;
    const { dropDownOptions, values } = this.state;

    if (!multi && allowSameValueInOptions) {
      return uniqBy(dropDownOptions, "id");
    }

    return uniqBy(dropDownOptions, "id").filter(
      (option) =>
        !find(values, { id: option.id }) &&
        !find(
          values.map(({ item }) => item),
          { id: option.id }
        )
    );
  };

  parseResponse = (response) => {
    const { parseResponse, formatOption, filterOptions } = this.props;

    const { inputValue } = this.state;

    if (parseResponse) {
      response = parseResponse(response);
    }

    if (filterOptions) {
      response = filterOptions(response, canonicalId(inputValue));
    }

    if (formatOption) {
      return response.map((option) => formatOption(option)).filter(Boolean);
    }

    // Here to avoid objects passed from API that are not supported (location) without proper parsing
    response = filter(response, (item) => isString(item.text) || isNumber(item.text));

    return response.map((option) => ({
      id: canonicalId(option.text),
      displayElement: option.displayElement || <span>{option.text}</span>,
      item: option,
      disabled: option.disabled
    }));
  };

  getDropDownOptions = () => {
    const { inputValue, values } = this.state;
    const { letCreate, maxLength } = this.props;

    if (maxLength && values.length >= maxLength) {
      return [];
    }

    const filteredDropDownOptions = this.filterOptions();

    const term = canonicalId(inputValue);

    if (letCreate && term && term.length > 0) {
      const termInOptions = find(filteredDropDownOptions, (option) => option.id === term);

      if (!termInOptions) {
        const termInValues = find(values, (value) => value.id === term);

        if (!termInValues) {
          const isTermDisabledValue =
            this.props.disabledValues.length > 0 && this.props.disabledValues.includes(term);

          if (!isTermDisabledValue) {
            filteredDropDownOptions.unshift({
              id: term,
              displayElement: (
                <span data-qa-id="New value" className={css.new}>
                  Create &ldquo;<span>{inputValue}</span>&rdquo;
                </span>
              ),
              item: { id: canonicalId(inputValue), text: inputValue, created: true }
            });
          }
        }
      }
    }
    return filteredDropDownOptions;
  };

  // Dropdown Handlers
  handleDropDownOpen = () => {
    this.setState({ isFocused: true });
    this.debouncedQueryOptions();
  };

  handleDropdownValuesChange = (values) => {
    const { handleChange } = this.props;

    this.setState({ values, inputValue: "" });
    handleChange && handleChange(map(values, "item"));
  };

  handleSingleDropDownChange = (item) => {
    const values = [canonicalValue(item)];

    this.handleDropdownValuesChange(values);
  };

  handleMultiDropDownChange = (_, option) => {
    const values = [...this.state.values, canonicalValue(option.item)];

    this.handleDropdownValuesChange(values);
  };

  handleBadgeDateChange = (item, event) => {
    event.stopPropagation();
    event.preventDefault();

    const { handleChange } = this.props;

    const values = [...this.state.values];
    const changedItemIndex = values.findIndex((stateItem) => stateItem.item.id === item.id);
    values[changedItemIndex] = canonicalValue(item);

    this.setState({ values });

    handleChange && handleChange(map(values, "item"));
  };

  handleDropDownClose = () => {
    const { multi } = this.props;

    this.setState({ inputValue: "", dropDownOptions: [], loaded: false });
    if (!multi) {
      this.setState({ isFocused: false });
    }
    this.props.handleDropDownOpen?.(false);
  };

  // Input Handlers
  handleInputChange = (value) => {
    this.setState({ inputValue: value }, this.debouncedQueryOptions);
  };

  handleRemove = (value, e) => {
    e.preventDefault();
    e.stopPropagation();

    const { handleChange, handleRemove, disabled } = this.props;
    const { values } = this.state;

    if (disabled) {
      return;
    }

    const newVals = reject(values, { id: value.id });

    this.setState({ values: newVals });
    handleChange && handleChange(map(newVals, "item"));
    handleRemove && handleRemove(map(newVals, "item"), value.item);
  };

  handleClearLinkClick = (event) => {
    const { handleChange } = this.props;

    event && event.stopPropagation();
    event && event.preventDefault();
    this.setState({ isFocused: false, inputValue: "", dropDownOptions: [], values: [] });

    handleChange && handleChange([]);
  };

  render() {
    const {
      kind,
      label,
      labelTooltip,
      locked,
      lockedTip,
      lockedTipPosition,
      letClear,
      tip,
      multi,
      error,
      disabled,
      style,
      selectStyles,
      closeOnChange,
      hideDisplayValuesOnFocus,
      maxLength,
      qaId,
      id,
      alwaysEmpty,
      allowSameValueInOptions,
      rootClassName,
      fitDropdownContent,
      dropDownClassName,
      placeholder,
      displayValues,
      displayValuesBelow,
      noOptionsText,
      required,
      icon,
      title,
      onRestore,
      restoreLabel,
      portalRef
    } = this.props;

    const { inputValue, isFocused } = this.state;

    const { values, loaded } = this.state;

    const active = !!inputValue || values.length > 0 || isFocused || placeholder?.length > 0;
    const showClearLink = !locked && letClear && !disabled && (values.length > 0 || displayValues);
    const options = this.getDropDownOptions();
    const selectedIndex =
      allowSameValueInOptions && !multi && values.length > 0
        ? findIndex(options, ({ id }) => id === values[0].id)
        : null;

    if (alwaysEmpty && values.length > 0) {
      this.setState({ values: [] });
    }

    const actionIcons = [
      onRestore && {
        name: "history",
        onClick: this.props.onRestore,
        title:
          restoreLabel ||
          (label
            ? i18n.t("core:components.autoselect.restoreValue", { label })
            : i18n.t("core:components.autoselect.restore"))
      },
      showClearLink && {
        name: "filter-clean",
        onClick: this.handleClearLinkClick,
        title: label
          ? i18n.t("core:components.autoselect.clearValue", { label })
          : i18n.t("core:components.autoselect.clear")
      }
    ];

    return (
      <div
        ref={this.autoSelectRef}
        data-qa-id={qaId}
        id={id}
        style={style}
        className={classNames(
          css.autoselect,
          { [css.single]: !multi },
          { [css.multi]: multi },
          { [css.locked]: locked },
          {
            [css.inputDown]:
              !disabled && ((isFocused && values.length > 0) || values.length === 0 || placeholder)
          },
          rootClassName
        )}
      >
        <Select
          portalRef={portalRef}
          kind={kind}
          ref={this.selectRef}
          placeholder={placeholder}
          label={label}
          labelTooltip={labelTooltip}
          title={title}
          locked={locked}
          lockedTip={lockedTip}
          lockedTipPosition={lockedTipPosition}
          error={error}
          tip={tip}
          disabled={disabled}
          value={isString(displayValues) && !this.props.showValues ? displayValues : inputValue}
          controlledValue
          selectedIndex={selectedIndex}
          icon={icon}
          displayValues={(!isString(displayValues) && displayValues) || this.renderValues()}
          displayValuesBelow={displayValuesBelow}
          closeOnChange={closeOnChange}
          hideDisplayValuesOnFocus={hideDisplayValuesOnFocus}
          active={active}
          style={selectStyles}
          options={!(maxLength && values.length >= maxLength) ? options : []}
          onOpen={this.handleDropDownOpen}
          onInputChange={this.handleInputChange}
          onChange={multi ? this.handleMultiDropDownChange : this.handleSingleDropDownChange}
          onClose={this.handleDropDownClose}
          preOptions={this.props.preOptions}
          onDropDownToggle={this.props.handleDropDownToggle}
          fitDropdownContent={fitDropdownContent}
          dropdownClassName={dropDownClassName}
          required={required}
          noOptionsText={loaded && options.length === 0 && noOptionsText}
          isMultiSelect={multi}
          allowSameValueInOptions={allowSameValueInOptions}
          inputFieldSetProps={{
            "aria-autocomplete": "list",
            "aria-multiselectable": multi ? "true" : "false",
            "actions": actionIcons
          }}
        />
      </div>
    );
  }

  renderValue() {
    const { values } = this.state;
    const value = values[0];
    return value && value.displayElement;
  }

  renderValues = () => {
    const { disabled, hasValuesWithExpiryDate, locked, multi, suffixElement } = this.props;
    if (!this.props.showValues || !this.state.values || this.state.values.length === 0) {
      return null;
    }

    return (
      <AutoSelectValues
        values={this.state.values}
        onRemove={(val, e) => this.handleRemove(val, e)}
        onDateChange={(item, e) => this.handleBadgeDateChange(item, e)}
        locked={locked}
        multi={multi}
        disabled={disabled}
        suffixElement={suffixElement}
        hasValuesWithExpiryDate={hasValuesWithExpiryDate}
      />
    );
  };
}

AutoSelect.propTypes = {
  kind: PropTypes.string,
  multi: PropTypes.bool,
  allowSameValueInOptions: PropTypes.bool, // only works with multi==false
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      locked: PropTypes.bool
    })
  ),
  displayValues: PropTypes.node,
  displayValuesBelow: PropTypes.bool,
  locked: PropTypes.bool,
  lockedTip: PropTypes.string,
  lockedTipPosition: PropTypes.oneOf(["top", "bottom"]),
  tip: PropTypes.string,
  letCreate: PropTypes.bool,
  letClear: PropTypes.bool,
  cache: PropTypes.bool,
  maxLength: PropTypes.number,
  closeOnChange: PropTypes.bool,
  hideDisplayValuesOnFocus: PropTypes.bool,
  query: PropTypes.func.isRequired, // return a promise
  onQueryError: PropTypes.func,
  labelTooltip: PropTypes.shape({
    icon: PropTypes.string,
    content: PropTypes.string
  }),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  title: PropTypes.string,

  error: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  selectStyles: PropTypes.object,
  rootClassName: PropTypes.string,
  fitDropdownContent: PropTypes.bool,
  dropDownClassName: PropTypes.string,
  handleChange: PropTypes.func,
  handleDropDownOpen: PropTypes.func,
  handleRemove: PropTypes.func,
  handleDropDownToggle: PropTypes.func,
  parseResponse: PropTypes.func,
  filterOptions: PropTypes.func,
  formatOption: PropTypes.func,
  saveRef: PropTypes.func,
  qaId: PropTypes.string,
  id: PropTypes.string,
  alwaysEmpty: PropTypes.bool,
  placeholder: PropTypes.string,
  showValues: PropTypes.bool,
  icon: PropTypes.node,
  suffixElement: PropTypes.shape({ className: PropTypes.string, text: PropTypes.node }),
  preOptions: PropTypes.node,
  noOptionsText: PropTypes.string,
  hasValuesWithExpiryDate: PropTypes.bool,
  debounceWait: PropTypes.number,
  required: PropTypes.bool,
  disabledValues: PropTypes.arrayOf(PropTypes.string),
  onRestore: PropTypes.func,
  restoreLabel: PropTypes.string,
  portalRef: PropTypes.object
};

AutoSelect.defaultProps = {
  multi: false,
  allowSameValueInOptions: false,
  values: null, // no [] here because it would lead to all components sharing one array
  locked: false,
  lockedTip: null,
  tip: null,
  letCreate: false,
  letClear: false,
  cache: true,
  maxLength: null,
  closeOnChange: false,
  hideDisplayValuesOnFocus: false,

  error: null,
  disabled: false,

  parseResponse: (data) => (Array.isArray(data.entries) ? data.entries : data), // there's [].entries fn!
  formatOption: null,

  showValues: true,
  displayValues: null,
  hasValuesWithExpiryDate: false,
  debounceWait: 500,
  disabledValues: []
};

export default AutoSelect;
