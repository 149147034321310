import isNil from "lodash/isNil";
import AutoSelect from "PFComponents/select/autoselect";
import { mapProfileToBasicProfile } from "PFCore/helpers/profile";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { BasicProfile, Collection } from "PFTypes";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ProfileItem } from "../../../../components/profile_item";
import { BookingFormValues } from "../booking_form_provider";
import { BookingFormData, BookingFormDataCreateMode, BookingFormMode } from "../use_booking_form";
import { BulkSelectItem } from "./bulk_select_item";
import { PROFILES_COUNT_LIMIT } from "./workforce_member_select.utils";

export const isGroupOption = (option?: BasicProfile | GroupBookingOption): option is GroupBookingOption =>
  !!option && "searchId" in option;
const fetchProfileOptions = (term) => fetchProfilesOptions({ term });
const getDisplayValues = (selectedUserDropdownOption?: BasicProfile | GroupBookingOption | null) => {
  if (!selectedUserDropdownOption) {
    return null;
  }
  if ("searchId" in selectedUserDropdownOption) {
    return selectedUserDropdownOption.searchId ? selectedUserDropdownOption.displayElement : null;
  }
  return <ProfileItem profile={selectedUserDropdownOption} />;
};

export type GroupBookingOption = {
  searchId: number;
  displayElement: ReactNode;
  disabled: boolean;
};

type WorkforceMemberSelectProps = {
  initialData: Partial<BookingFormData>;
  mode: BookingFormMode;
};

export const WorkforceMemberSelectDropdown = ({ initialData, mode }: WorkforceMemberSelectProps) => {
  const { t } = useTranslation("bookingModule");
  const { setValue, control } = useFormContext<BookingFormValues>();
  const workforceMember = useWatch({ name: "workforceMember" });

  const groupBookingOption: GroupBookingOption | null = useMemo(() => {
    if (mode !== BookingFormMode.Create) {
      return null;
    }
    const groupData = (initialData as BookingFormDataCreateMode).groupBookingData;
    if (isNil(groupData) || isNil(groupData?.searchId)) {
      return null;
    }

    const bulkOptionDisabled = (groupData.total ?? 0) > PROFILES_COUNT_LIMIT;
    return {
      searchId: groupData.searchId,
      displayElement: <BulkSelectItem count={groupData.total || 0} disabled={bulkOptionDisabled} />,
      disabled: bulkOptionDisabled
    };
  }, [mode, initialData]);

  const parseProfileResponse = useCallback(
    (response: Collection<BasicProfile[]>) => {
      const sortedProfiles = (response.entries as BasicProfile[]).sort((profileA, profileB) =>
        (profileA?.firstName ?? "").localeCompare(profileB?.firstName ?? "")
      );

      return [...(groupBookingOption ? [groupBookingOption] : []), ...sortedProfiles];
    },
    [groupBookingOption]
  );

  useEffect(() => {
    if (!isGroupOption(workforceMember)) {
      return;
    }
    setValue("workforceMember", groupBookingOption ?? undefined);
  }, [groupBookingOption]);

  return (
    <Controller
      control={control}
      name="workforceMember"
      rules={{
        required: true
      }}
      render={({ field }) => (
        <AutoSelect
          label={t("bookings.create.workforceMember")}
          displayValues={getDisplayValues(field.value)}
          closeOnChange
          letClear={mode === BookingFormMode.Create}
          values={field.value ? [field.value] : undefined}
          query={fetchProfileOptions}
          formatOption={(item) => ({
            id: item?.id,
            displayElement: item.displayElement || <ProfileItem profile={item} />,
            item,
            disabled: item.disabled
          })}
          handleChange={(user) => {
            const isGroupOption = !!user[0]?.searchId;
            if (user[0]) {
              const profile = isGroupOption ? user[0] : mapProfileToBasicProfile(user[0]);
              field.onChange(profile);
            } else {
              field.onChange(null);
            }
          }}
          parseResponse={parseProfileResponse}
          required
          cache={false}
          hideDisplayValuesOnFocus
        />
      )}
    />
  );
};
