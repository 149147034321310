import { useExperienceConfig } from "PFApp/hooks/use_experience_config";
import { MultiToggle } from "PFComponents/multi_toggle";
import { Experience } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./experience_multi_toggle.module.scss";
import { ExperienceOption } from "./experience_option";

type ExperienceMultiToggleProps = {
  experience: Experience | null;
  isRequirement?: boolean;
  onChange: (value: Experience) => void;
};

const levelsCountToVariantMap = {
  3: "body",
  4: "label",
  5: "labelSmall"
};

export const ExperienceMultiToggle = ({
  experience,
  isRequirement,
  onChange
}: ExperienceMultiToggleProps) => {
  const { t } = useTranslation();
  const { experienceLevels } = useExperienceConfig();

  const experienceOptions = useMemo(
    () =>
      experienceLevels.map((experienceOption) => ({
        id: experienceOption,
        value: (
          <ExperienceOption
            experience={experienceOption}
            isRequirement={isRequirement}
            selected={experience === experienceOption}
          />
        )
      })),
    [isRequirement, experience, experienceLevels]
  );

  return (
    <MultiToggle
      label={`${t("proficiency")} *`}
      fitHeightToContent
      options={experienceOptions}
      controlledValue={experience}
      textVariant={levelsCountToVariantMap[experienceLevels.length]}
      onChange={onChange}
      classes={{
        buttonLabel: css.fullWidth
      }}
    />
  );
};
