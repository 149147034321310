import find from "lodash/find";
import { useProficiencyLevels } from "PFCore/hooks/queries/proficiency_levels/use_proficiency_levels";
import { Experience } from "PFTypes";

export const useExperienceLabelMap = () => {
  const { data } = useProficiencyLevels();

  const getLabel = (experience: Experience) => find(data, { numericalValue: experience })?.displayAs || "-";

  return Object.values(Experience).reduce((acc, experience: Experience) => {
    acc[experience] = getLabel(experience);
    return acc;
  }, {}) as Record<Experience, string>;
};
