import { ExperienceMultiToggle } from "PFApp/components/experience_multi_toggle";
import { useExperienceConfig } from "PFApp/hooks/use_experience_config";
import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import { Inline } from "PFComponents/containers/flex/inline";
import { ExperienceLevel } from "PFComponents/experience_level";
import { Typography } from "PFComponents/typography";
import { Experience } from "PFTypes";
import { useTranslation } from "react-i18next";

import { DataEntry } from "../data_entry";

type ExperienceToggleProps = {
  canEdit?: boolean;
  experience: Experience | null;
  isRequirement: boolean;
  onChange: (value: Experience) => void;
};

export const ExperienceToggle = ({ canEdit, experience, isRequirement, onChange }: ExperienceToggleProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "customValueSidepanel" });
  const experienceLabelMap = useExperienceLabelMap();
  const { experienceLevels } = useExperienceConfig();

  return canEdit ? (
    <ExperienceMultiToggle experience={experience} isRequirement={isRequirement} onChange={onChange} />
  ) : (
    <DataEntry
      label={t("proficiency")}
      value={
        <Inline alignItems="center" gap="spacingSm">
          <ExperienceLevel
            isRequirement={isRequirement}
            {...{ [isRequirement ? "requiredExperience" : "experience"]: experience }}
            showTooltip={false}
            experienceLevels={experienceLevels}
          />
          <Typography variant="labelBold">{experienceLabelMap[experience!]}</Typography>
        </Inline>
      }
    />
  );
};
