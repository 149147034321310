import classNames from "classnames";
import find from "lodash/find";
import { useExperienceConfig } from "PFApp/hooks/use_experience_config";
import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import { Flex, Inline } from "PFComponents/containers";
import { DropdownButton } from "PFComponents/dropdown_button";
import { ExperienceLevel } from "PFComponents/experience_level";
import { Typography } from "PFComponents/typography";
import { useProficiencyLevels } from "PFCore/hooks/queries/proficiency_levels/use_proficiency_levels";
import { Experience } from "PFTypes";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import { DEFAULT_SORTING_OPTION, useSortingOptions } from "../hooks/use_sorting_options";
import css from "../skills_modal.module.scss";
import { DraggableSkill } from "./draggable_skill";

const experienceToBareTextMap = {
  [Experience.Basic]: "basic",
  [Experience.Developing]: "developing",
  [Experience.Intermediate]: "intermediate",
  [Experience.Proficient]: "proficient",
  [Experience.Advanced]: "advanced"
};

const levelsCountToVariantMap = {
  3: "h5",
  4: "bodyBold",
  5: "bodyBold"
};

export const Header = ({ experience, skills, skillsSortingSelected, setSkillsSortingSelected }) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts" });
  const { data: proficiencyLevels } = useProficiencyLevels();

  const { skillsSortingOptions } = useSortingOptions();
  const experienceLabelMap = useExperienceLabelMap();
  const { experienceLevels } = useExperienceConfig();

  const title = `${experienceLabelMap[experience]} ${skills.length}`;
  const isVerticalLayout = experienceLevels.length > 4;

  return (
    <div className={classNames(css.header, css[experienceToBareTextMap[experience]])}>
      <Inline className={css.title} alignItems="center">
        <Flex
          alignItems={isVerticalLayout ? "flex-start" : "center"}
          direction={isVerticalLayout ? "column-reverse" : "row"}
          wrap={isVerticalLayout}
          className={css.experience}
        >
          <ExperienceLevel experience={experience} experienceLevels={experienceLevels} showTooltip={false} />
          <Typography
            variant={levelsCountToVariantMap[experienceLevels.length] ?? "h4"}
            title={title}
            noMargin
            clipOverflow
            className={css.experienceText}
          >
            {title}
          </Typography>
        </Flex>
        <DropdownButton
          actionIcon="sort"
          small
          options={skillsSortingOptions}
          handleChange={(item) => {
            setSkillsSortingSelected({
              ...skillsSortingSelected,
              [experience]: item
            });
          }}
          dropdownProps={{
            selectedIndex: skillsSortingOptions.findIndex(
              ({ item }) => item === (skillsSortingSelected[experience] || DEFAULT_SORTING_OPTION)
            )
          }}
          aria-label={t("skillsModal.changeExperienceSorting", {
            experience: experienceLabelMap[experience]
          })}
          className={css.sortIcon}
        />
      </Inline>
      <Typography variant="labelRegular" tag="span" className={css.info}>
        {find(proficiencyLevels, { numericalValue: experience })?.definition || "-"}
      </Typography>
    </div>
  );
};

Header.propTypes = {
  experience: PropTypes.number.isRequired,
  skills: PropTypes.arrayOf(PropTypes.object).isRequired,
  skillsSortingSelected: PropTypes.object.isRequired,
  setSkillsSortingSelected: PropTypes.func.isRequired
};

export const Skills = ({
  experience,
  skills,
  addToLog,
  coreSkillsCount,
  developmentalSkillsCount,
  portalElement
}) => (
  <div className={classNames(css.skills, css[experienceToBareTextMap[experience]])}>
    <Droppable droppableId={String(experience)}>
      {(provided) => (
        <div className={css.droppable} ref={provided.innerRef} {...provided.droppableProps}>
          <div className={css.skillsInner}>
            {skills.map((skill, index) => (
              <DraggableSkill
                key={skill.id}
                skill={skill}
                index={index}
                addToLog={addToLog}
                coreSkillsCount={coreSkillsCount}
                developmentalSkillsCount={developmentalSkillsCount}
                portalElement={portalElement}
              />
            ))}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  </div>
);

Skills.propTypes = {
  experience: PropTypes.number.isRequired,
  skills: PropTypes.arrayOf(PropTypes.object).isRequired,
  addToLog: PropTypes.func.isRequired,
  coreSkillsCount: PropTypes.number.isRequired,
  developmentalSkillsCount: PropTypes.number.isRequired,
  portalElement: PropTypes.object
};

const SkillsGrid = ({
  skillsByExperience,
  skillsSortingSelected,
  setSkillsSortingSelected,
  addToLog,
  coreSkillsCount,
  developmentalSkillsCount,
  portalElement
}) => {
  const { experienceLevels } = useExperienceConfig();

  return (
    <div
      className={css.skillRatingGrid}
      style={{
        gridTemplateColumns: Array(experienceLevels.length).fill("minmax(0, 1fr)").join(" ")
      }}
    >
      {experienceLevels.map((experience) => (
        <Fragment key={experience}>
          <Header
            key={experience}
            experience={experience}
            skills={skillsByExperience[experience] || []}
            skillsSortingSelected={skillsSortingSelected}
            setSkillsSortingSelected={setSkillsSortingSelected}
            addToLog={addToLog}
          />
          <Skills
            key={experience}
            experience={experience}
            skills={skillsByExperience[experience] || []}
            addToLog={addToLog}
            coreSkillsCount={coreSkillsCount}
            developmentalSkillsCount={developmentalSkillsCount}
            portalElement={portalElement}
          />
        </Fragment>
      ))}
    </div>
  );
};

SkillsGrid.propTypes = {
  skillsByExperience: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
  skillsSortingSelected: PropTypes.object.isRequired,
  setSkillsSortingSelected: PropTypes.func.isRequired,
  addToLog: PropTypes.func.isRequired,
  coreSkillsCount: PropTypes.number.isRequired,
  developmentalSkillsCount: PropTypes.number.isRequired,
  portalElement: PropTypes.object
};

export default SkillsGrid;
