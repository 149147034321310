import classNames from "classnames";
import { Inline, Stack } from "PFComponents/containers";
import { Typography } from "PFComponents/typography";
import { ExperienceLevel } from "PFCore/components/experience_level";
import CheckIcon from "PFIcons/check_3.svg";
import colors from "PFTheme/tokens/colors";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import css from "./category_tooltip.module.scss";
import { getAllSkillsFromTree, groupSkillsByExperience } from "./helpers";

const CategoryTooltip = ({ data, color, currentAccount, experienceLevels, experienceLabelMap }) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts" });

  if (typeof document === "undefined") {
    React.useLayoutEffect = React.useEffect;
  }

  if (!data || !data.data) {
    return false;
  }

  const allSkills = getAllSkillsFromTree(data.data);
  const skillsByExperience = groupSkillsByExperience(allSkills);
  const validatedSkills = allSkills.filter((item) => item.assessed);
  const subtitle = t("charts.countValidatedSkills", {
    validatedCount: validatedSkills.length,
    allCount: allSkills.length
  });

  const isValidatedSkillsInfoVisible = currentAccount.profile_view_customization.validated_skill_text;

  return (
    <div className={css.hoverWrap}>
      <div className={css.hoverTitle}>
        <div className={css.title}>
          <h4>{data.name}</h4>
          <span>{t("charts.category")}</span>
        </div>
        {isValidatedSkillsInfoVisible && (
          <div className={css.actions}>
            <CheckIcon className={classNames(css.icon, css.verified)} size={20} />
            <p>{subtitle}</p>
          </div>
        )}
      </div>
      <Inline
        style={{ backgroundColor: color }}
        justifyContent="space-around"
        gap="spacingLg"
        className={css.hoverContent}
      >
        {experienceLevels.map((experience) => (
          <Stack key={experience} center>
            <Typography variant="bodyRegular" className={css.experienceLabel} clipOverflow noMargin>
              {experienceLabelMap[experience]}
            </Typography>
            <ExperienceLevel
              size="md"
              experience={experience}
              color={colors.paletteWhite0}
              experienceLevels={experienceLevels}
            />
            <Typography variant="bodyRegular" noMargin>
              {(skillsByExperience[experience] || []).length}
            </Typography>
          </Stack>
        ))}
      </Inline>
    </div>
  );
};

CategoryTooltip.propTypes = {
  color: PropTypes.string,
  data: PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.object
  }),
  currentAccount: PropTypes.shape({
    // eslint-disable-next-line camelcase
    profile_view_customization: PropTypes.shape({
      validated_skill_text: PropTypes.bool // eslint-disable-line camelcase
    })
  }),
  experienceLevels: PropTypes.arrayOf(PropTypes.number),
  experienceLabelMap: PropTypes.object
};

export default CategoryTooltip;
